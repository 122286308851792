body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, Arial, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "PingFang", "Helvetica Neue", STHeiti, "Microsoft Yahei", Tahoma, SimSun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  background-color: #f5f5f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.flex {
  display: flex;
}
.flex-direction-row {
  flex-direction: row
}
.flex-direction-column {
  flex-direction: column;
}

.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-around {
  justify-content: space-around;
}

.flex-align-start {
  align-items: flex-start;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-align-center {
  align-items: center;
}
.flex-align-baseline {
  align-items: baseline;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.wp100 {
  width: 100%;
}
.hp100 {
  height: 100%;
}

input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #e38786;
  /* placeholder字体大小  */
  font-size: .12rem;
}

.am-toast-notice {
  transform: scale(1.2)
}
.ant-list-item {
  /* padding: 20px 0; */
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom-color: #f5f5f5 !important;

}
.ant-list-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.ant-btn-circle.ant-btn-sm {
  font-size: 10px;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-item:last-child {
  border-bottom: 0;
}
.subsribe-bell {
  font-size: 14px;
  color: #07c160;
  cursor: pointer;
  margin-left: 6px;
  display: inline-block;
  position: relative;
  top: -1px;
  animation-name: shake-key;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes shake-key {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(0);
  }
  60% {
    transform: rotate(15deg);
  }
  70% {
    transform: rotate(-15deg);
  }
  80% {
    transform: rotate(15deg);
  }
  90% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes fade-in-key {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
}

a:focus {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  text-decoration-color: #e5e5e6;
  text-decoration-style: solid;
  text-underline-offset: 5px
}
.link-container-link {
  color: #888;
}
.link-container-link:hover {
  color: #000;
}
.ant-btn-circle {
  border-color: transparent;
  box-shadow: 0 0 5px 3px rgba(0,0,0,.05);
  color: #bbb;
  transition: all .3s ease-in;
  /* width: 25px;
  height: 25px; */
  /* font-weight: 300; */
}
/* .anticon-picture { */
  /* font-size: 12px !important; */
  /* line-height: 12px; */
/* } */
/* .ant-btn-group { */
  /* height: 25px;
  line-height: 25px; */
/* } */
.ant-btn-ghost {
  color: #bbb;
  border-color: transparent;
  box-shadow: 0 0 5px 3px rgba(0,0,0,.05);
}
.pic-modal .ant-modal-confirm-content {
  margin-left: 0 !important;
}
.pic-modal .anticon, .pic-modal .ant-modal-confirm-title {
  display: none;
}
/* .pic-modal .ant-modal-confirm-btns, .pic-modal .ant-modal-confirm-btns .ant-btn {
  width: 100%;
} */
.ant-switch-checked {
  background-color: #15878b;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: none!important;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}
.ant-back-top {
  bottom: 150px;
}

.ant-modal-confirm-content {
  margin-left: 0 !important;
}
@media screen and (max-width: 1000px) {
  .ant-modal {
    width: 90% !important;
  }
}

.ant-modal {
  top: .5rem;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 0;
}
.ant-modal-confirm .ant-modal-body {
  padding: 6px 12px;
}
.pop-closable {
  position: absolute;
  bottom: -45px;
  width: calc(100% - 24px);
  display: flex;
  justify-content: center;
  padding: 10px 0;
  color: rgba(255,255,255,.5);
  cursor: pointer;
  transition: color .3s ease;
  font-size: 24px;
  &:hover {
    color: rgba(255,255,255,1);
  }
}