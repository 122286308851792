* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-input-placeholder { /* WebKit browsers */
　color: #fff !important
}

body,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
img,
legend,
li,
ol,
p,
ul {
    margin: 0;
    padding: 0;
}

fieldset,
img {
    border: none;
}

address,
caption,
cite,
code,
dfn,
th,
var {
    font-style: normal;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

input, textarea {
    outline: none !important;
    border: none;
}
a {
  text-decoration: none;
}
body,
html {
  width: 100%;
  height: 100%;
  /* font-size: .28rem; */
}

html {
  overflow-x: hidden;
}

body {

  text-rendering: opximizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: PingFangSC-Regular, verdana, Arial !important;
}
