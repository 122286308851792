.block-title {
  padding: 0 0.41rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block-title .title {
  color: #333;
  font-size: 0.16rem;
  font-weight: 300;
}
.block-title .title .block-title-bar {
  height: 0.12rem;
  margin-right: 0.06rem;
  display: inline-block;
  width: 0.04rem;
  border-radius: 0.06rem;
  background-color: #5f5f5f;
  position: relative;
  top: 0;
}
.block-title.normal {
  padding: 0 0.25rem;
}
.block-title.normal .title {
  font-weight: 400;
  font-size: 0.26rem;
}
.block-title.larger .title {
  font-size: 0.4rem;
}
.block-title.larger .title .block-title-bar {
  height: 0.38rem;
}
.block-title.no-padding {
  padding: 0;
}
.block-title.secondary {
  padding: 0;
}
.block-title.secondary .title {
  font-weight: 300;
  font-family: Arial !important;
  font-size: 0.14rem;
  color: #454545;
}
.block-title.separator {
  padding-bottom: 0.2rem;
  border-bottom: 0.01rem solid #f5f5f6;
}
.block-title .extra-icon {
  width: 0.14rem;
  height: auto;
}
.block-title .extra {
  color: #666;
  font-family: Arial !important;
  font-size: 0.12rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  transition: color 0.5s ease;
}
.block-title .extra i {
  color: #666;
  transition: color 0.5s ease;
}
.block-title .extra:hover {
  color: #333;
  font-weight: 400;
}
.block-title .extra:hover i {
  color: #333;
}
.block-title .extra .arrow {
  width: 0.13rem;
  height: auto;
  position: relative;
  top: -0.01rem;
  margin-left: 0.03rem;
}

.wenan-card {
  font-weight: 400;
  width: 100%;
  background-color: #fff;
  overflow: visible;
  border: 0.01rem solid #f8f8f8;
  box-sizing: border-box;
  border-radius: 0.03rem;
  overflow: hidden;
  position: relative;
  margin-bottom: 0.12rem;
  transition: border-color 0.3s linear;
}
.wenan-card-scan {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
.wenan-card-scan img {
  width: 1.5rem;
}
.wenan-card.no-border {
  border: none;
  transition: box-shadow 0.3s linear;
}
.wenan-card.animate {
  animation: fade-in-key 0.5s linear 1;
}
.wenan-card-scan-tips {
  position: absolute;
  top: -0.03rem;
  right: -0.03rem;
  opacity: 0;
  transform: rotate(90deg);
  transition: all 0.3s ease-in;
  cursor: pointer;
  z-index: 9;
  box-shadow: inset 0.03rem 0.03rem 0.03rem rgba(0, 0, 0, 0.03);
}
.wenan-card-scan-tips img {
  width: 0.25rem;
  opacity: 0.7;
}
.wenan-card:hover {
  border-color: #eee;
}
.wenan-card:hover .wenan-card-scan-tips {
  top: 0.03rem;
  right: 0.03rem;
  opacity: 1;
}
.wenan-card:hover .wenan-card-action {
  opacity: 1;
}
.wenan-card:hover .wenan-card-hot {
  opacity: 0;
}
.wenan-card:hover .wenan-card-text.word,
.wenan-card:hover .wenan-card-thumb {
  transform: scale(0.95);
  text-shadow: 0.03rem 0.03rem 0.1rem rgba(0, 0, 0, 0.2);
}
.wenan-card:hover .wenan-card-thumb {
  transform-origin: center;
}
.wenan-card:hover.no-border {
  box-shadow: 0 0 0.1rem 0.05rem rgba(0, 0, 0, 0.1);
}
.wenan-card-thumb {
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.9;
  position: relative;
  transition: all 0.5s ease;
  transform-origin: top;
}
.wenan-card-thumb img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.wenan-card-thumb-tip {
  position: absolute;
  padding: 0.08rem;
  cursor: pointer;
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 0.16rem);
  left: calc(50% - 0.16rem);
  transition: opacity 0.5s ease-in;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.wenan-card-thumb-tip i {
  color: #fff;
  font-size: 0.2rem;
}
.wenan-card-thumb:hover .wenan-card-thumb-tip {
  opacity: 1;
}
.wenan-card.dark .wenan-card-text {
  color: rgba(255, 255, 255, 0.8);
}
.wenan-card-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.12rem;
  font-weight: 3rem;
  font-family: Arial;
  text-align: justify;
  height: 2.05rem;
  overflow: hidden;
  overflow-y: auto;
  letter-spacing: 0.03rem;
}
.wenan-card-text-content {
  padding-bottom: 0.12rem;
}
.wenan-card-text-content-light {
  color: #ff4848;
}
.wenan-card-text.word {
  margin: 0.15rem;
  -webkit-user-select: none;
          user-select: none;
  transition: all 0.5s ease;
  transform-origin: top;
  text-shadow: 0.01rem 0.01rem 0.03rem rgba(0, 0, 0, 0.2);
}
.wenan-card-info {
  padding: 0.1rem;
  box-sizing: border-box;
  box-shadow: 0 0.03rem 0.05rem 0 rgba(0, 0, 0, 0.03);
}
.wenan-card-info-user {
  opacity: 0.8;
}
.wenan-card-info-user-headimgurl {
  width: 0.28rem;
  height: 0.28rem;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.08rem;
}
.wenan-card-info-user-headimgurl img {
  width: 100%;
  height: auto;
  border-radius: 100%;
}
.wenan-card-info-user-name {
  font-size: 0.12rem;
  color: #888;
  text-align: center;
  max-width: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wenan-card-info-user-operate {
  font-size: 0.1rem;
  color: #ccc;
}
.wenan-card-info-hot {
  font-size: 0.28rem;
  color: #FF4848;
}
.wenan-card-hot {
  font-size: 0.1rem;
  color: #ccc;
  bottom: 0.06rem;
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 1;
  transition: opacity 0.5s ease;
}
.wenan-card-action {
  display: flex;
  justify-content: space-between;
  padding: 0.12rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.wenan-card-action-btn {
  font-size: 0.14rem;
}

.wenan-action-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 0.9;
  transition: opacity 0.3s ease-in;
}
.wenan-action-group-share {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.wenan-action-group-share-item {
  cursor: pointer;
}
.wenan-action-group-share-item img {
  width: 0.35rem;
}
.wenan-action-group-copy-layer {
  width: 1rem;
  height: 1rem;
  position: relative;
  left: calc(50% - 0.5rem);
  background-color: #07c160;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wenan-action-group-copy-layer img {
  opacity: 0.9;
  width: 0.3rem;
  position: relative;
  left: 0.05rem;
  top: -0.1rem;
}
.wenan-action-group-copy-layer-tips {
  position: absolute;
  color: #fff;
  font-size: 0.1rem;
  width: 100%;
  bottom: 0.2rem;
  text-align: center;
}
.wenan-action-group .ant-btn-group {
  display: flex;
  margin: 0 0.12rem;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.05);
  border-radius: 0.2rem;
  overflow: hidden;
}
.wenan-action-group .ant-btn-group .ant-btn {
  border-color: transparent;
  color: #bbb;
  transition: all 0.3s ease-in;
}
.wenan-action-group .ant-btn-group .ant-btn:hover {
  color: #fff;
}
.wenan-action-group .ant-btn-group .ant-btn .anticon-copy {
  font-size: 0.13rem;
}
.wenan-action-group .ant-btn {
  background-color: rgba(255, 255, 255, 0.8);
}
.wenan-action-group .ant-btn .anticon-picture {
  font-size: 0.13rem;
}
.wenan-action-group .ant-btn .anticon-file-search {
  font-size: 0.13rem;
}
.wenan-action-group.end {
  justify-content: flex-end;
}
.wenan-action-group.full {
  width: 100%;
}
.wenan-action-group.between {
  justify-content: space-between;
}
.wenan-action-group.loading {
  opacity: 0;
}

.user-avatar {
  border-radius: 100%;
  background-color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.user-avatar-content {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
}
.user-avatar-content span {
  color: #e6e6e6 !important;
  font-size: 0.16rem;
}
.user-avatar-content img {
  width: 100%;
  height: 100%;
}
.user-avatar.small {
  width: 0.18rem;
  height: 0.18rem;
}
.user-avatar.small span {
  font-size: 0.12rem;
}
.user-avatar.middle {
  width: 0.28rem;
  height: 0.28rem;
}
.user-avatar.normal {
  width: 0.35rem;
  height: 0.35rem;
}
.user-avatar.large {
  width: 0.7rem;
  height: 0.7rem;
}
.user-avatar.large span {
  font-size: 0.22rem;
}
.user-avatar-source {
  position: absolute;
  bottom: -0.05rem;
  right: -0.05rem;
  width: 0.2rem !important;
  height: 0.2rem !important;
}

.load-more {
  text-align: center;
  margin-top: 0.2rem;
  height: 0.32rem;
}
.load-more .ant-btn {
  padding: 0 0.8rem;
  border-color: #333;
}

.roll {
  width: calc(100% - 0.12rem);
  height: auto;
  height: 3.82rem;
  padding: 0.1rem 0.12rem;
  box-sizing: border-box;
  margin-right: 0.12rem;
  background-color: #fff;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
}
.roll .ant-radio-group .ant-radio-button-wrapper {
  font-size: 0.12rem;
  border-color: #f5f5f6;
  color: #999;
}
.roll .ant-radio-group .ant-radio-button-wrapper:hover {
  color: #454545;
}
.roll .ant-radio-group .ant-radio-button-wrapper:focus {
  box-shadow: none;
  outline: none;
}
.roll .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: #454545;
  background-color: #fff;
  color: #454545;
  box-shadow: -0.01rem 0 0 0 #454545;
}
.roll .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked::before {
  display: none;
}
.roll-content {
  margin-top: 0.2rem;
  padding: 0 0.24rem;
  height: 2.57rem;
  font-weight: 200;
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}
.roll-content-word {
  -webkit-user-select: none;
          user-select: none;
  color: #3F4E4F;
  text-align: justify;
  font-size: 0.16rem;
  line-height: 0.28rem;
  letter-spacing: 0.03rem;
  text-shadow: 0.01rem 0.01rem 0.05rem rgba(0, 0, 0, 0.1);
  font-family: Arial;
  height: calc(2.3rem - 0.7rem);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.12rem;
  margin: 0.12rem 0;
  width: 100%;
}
.roll-content-word-author {
  font-weight: 700;
}
.roll-content-word-author a {
  color: #FF6000 !important;
  transition: opacity 0.3s ease-out;
}
.roll-content-word-author a:hover {
  color: #FF6000 !important;
  opacity: 0.7;
  -webkit-text-decoration-color: #FF6000;
          text-decoration-color: #FF6000;
}
.roll-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.2rem;
}
.roll-action-article {
  font-size: 0.1rem;
  color: #ccc;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.roll-action-article.loading {
  opacity: 0;
}
.roll-action-article a {
  font-size: 0.11rem;
  color: #bbb;
}
@media screen and (max-width: 1000px) {
  .roll {
    margin-right: 0 !important;
    width: 100%;
    margin-bottom: 0.12rem;
  }
  .roll .roll-content {
    padding: 0;
  }
}

.roll-shi {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border-radius: 0.03rem;
  position: relative;
  background-color: #6ea99f;
  margin-bottom: 0.14rem;
  overflow: hidden;
}
.roll-shi-pic {
  width: 60%;
  height: 4rem;
  overflow: hidden;
  background-color: #000;
  box-shadow: 0 0 0.1rem 0.02rem rgba(0, 0, 0, 0.5);
  -webkit-user-select: none;
          user-select: none;
}
.roll-shi-pic img {
  height: 100%;
  width: 100%;
  opacity: 0.8;
  object-fit: cover;
  animation-name: scale-breath;
  animation-duration: 30s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.roll-shi-content {
  border-radius: 0.03rem;
  width: 40%;
  color: #fff;
  padding: 0.24rem;
  font-weight: 400;
  box-sizing: border-box;
  overflow: visible;
}
.roll-shi-content-head {
  transition: opacity 0.5s ease-out;
  text-shadow: 0.02rem 0.02rem 0.05rem rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-bottom: 0.24rem;
}
.roll-shi-content-head.hide {
  opacity: 0;
}
.roll-shi-content-head-title {
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.18rem;
  font-weight: 300;
}
.roll-shi-content-head-author {
  font-size: 0.12rem;
  font-weight: 300;
  margin-top: 0.12rem;
  color: #eee;
}
.roll-shi-content-head-author-dynasty {
  margin-right: 0.12rem;
}
.roll-shi-content-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.roll-shi-content-footer-copy {
  transition: opacity 0.5s ease-out;
  opacity: 1;
}
.roll-shi-content-footer-copy.hide {
  opacity: 0.5;
}
.roll-shi-content-word {
  text-shadow: 0.02rem 0.02rem 0.05rem rgba(0, 0, 0, 0.3);
  -webkit-user-select: none;
          user-select: none;
  color: #fff;
  text-align: justify;
  font-size: 0.14rem;
  letter-spacing: 0.03rem;
  font-family: Arial;
  height: 2.14rem;
  overflow: auto;
  margin-bottom: 0.12rem;
}
.roll-shi-content-word-line {
  text-align: center;
}
@media screen and (max-width: 1000px) {
  .roll-shi {
    margin-right: 0 !important;
    width: 100%;
  }
  .roll-shi-pic {
    display: none;
  }
  .roll-shi-content {
    width: 100%;
  }
}
@keyframes scale-breath {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.gads {
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.gpt {
  width: 100%;
  padding: 0.1rem 0.12rem;
  box-sizing: border-box;
  position: relative;
  background-color: #CBE4DE;
  height: 2.15rem;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
  margin-bottom: 0.12rem;
}
.gpt-tips {
  color: #66b09e;
  font-size: 0.12rem;
  text-align: center;
}
.gpt-target {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 0 0.05rem 0;
}
.gpt-target-item {
  width: 33%;
  cursor: pointer;
  padding: 0.06rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in;
  border-radius: 0.06rem;
}
.gpt-target-item:hover {
  background-color: #badbd3;
}
.gpt-target-item-icon {
  text-align: center;
  margin-bottom: 0.03rem;
}
.gpt-target-item-icon img {
  width: 0.22rem;
}
.gpt-target-item-name {
  font-size: 0.12rem;
  font-weight: 700;
  text-align: center;
}
.gpt-target-item-tips {
  font-size: 0.12rem;
  color: #999;
  text-align: center;
  margin-top: 0.06rem;
}
.gpt-title {
  font-size: 0.16rem;
  color: #2E4F4F;
  font-weight: 300;
  text-align: center;
  border-radius: 0.03rem;
  background-color: #a9d3c9;
  padding: 0.06rem;
  margin-bottom: 0.12rem;
}
.gpt ul {
  text-align: center;
}
.gpt ul li {
  list-style-position: inside;
  color: #0E8388;
  padding: 0.06rem 0;
  letter-spacing: 0.03rem;
}
.gpt-btn .ant-btn {
  width: 100%;
  font-size: 0.16rem;
  height: 0.4rem;
}
.gpt-history {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.06rem 0 0.12rem 0;
}
.gpt-history span {
  background-color: #a9d3c9;
  color: #0E8388;
  font-size: 0.12rem;
  padding: 0.01rem 0.12rem;
  width: auto;
  text-align: center;
  border-radius: 0.2rem;
}
.gpt-history span a {
  color: #0E8388;
  text-decoration: underline;
}
@media screen and (max-width: 1000px) {
  .gpt {
    height: auto;
    padding: 0.06rem;
    border-radius: 0;
  }
  .gpt-target {
    margin-bottom: 0.06rem;
  }
  .gpt-target-item {
    padding: 0.12rem 0;
    background-color: #badbd3;
    margin: 0 0.06rem;
  }
  .gpt-title {
    background-color: #CBE4DE;
    margin-bottom: 0;
    padding: 0;
    padding-left: 0.12rem;
    font-size: 0.14rem;
    font-weight: 300;
  }
  .gpt-history {
    display: none;
  }
  .gpt ul {
    display: none;
  }
}

.article-list-item {
  width: 100%;
  height: 2.8rem;
  background-color: #fff;
  transition: box-shadow 0.3s linear;
  overflow: hidden;
  animation: fade-in-key 0.5s linear 1;
  position: relative;
  border-radius: 0.03rem;
}
.article-list-item-pic {
  height: 1.5rem;
  border-radius: 0.03rem 0.03rem 0 0;
  overflow: hidden;
  cursor: pointer;
}
.article-list-item.horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}
.article-list-item.horizontal .article-list-item-pic {
  width: 3rem;
  display: none;
}
.article-list-item.horizontal .article-list-item-content-create-time {
  display: none;
}
.article-list-item.horizontal .article-list-item-content {
  padding: 0 0.24rem;
  font-size: 0.12rem;
}
.article-list-item.full {
  margin-bottom: 0;
}
.article-list-item.full .article-list-item-pic {
  height: 3.2rem;
  border-radius: 0.05rem;
}
.article-list-item.full .article-list-item-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: auto;
  padding: 0.12rem;
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1) !important;
}
.article-list-item.full .article-list-item-content-title {
  color: #fff;
}
.article-list-item.full .article-list-item-content-sub-title {
  color: rgba(255, 255, 255, 0.8);
}
.article-list-item.full .article-list-item-content-create-time {
  display: none;
}
.article-list-item.lite {
  margin-bottom: 0.12rem;
  height: 2.15rem;
}
.article-list-item.lite:hover {
  box-shadow: 0 0 0.1rem 0.05rem rgba(0, 0, 0, 0.1);
}
.article-list-item.lite:hover .article-list-item-content {
  transform: scale(0.95);
}
.article-list-item.lite .article-list-item-content-title {
  margin-bottom: 0.03rem;
}
.article-list-item.lite .article-list-item-content-sub-title {
  display: none;
}
.article-list-item.lite .article-list-item-content-create-time {
  display: none;
}
.article-list-item.normal {
  margin-bottom: 0.12rem;
}
.article-list-item.normal:hover {
  box-shadow: 0 0 0.1rem 0.05rem rgba(0, 0, 0, 0.1);
}
.article-list-item.normal:hover .article-list-item-content {
  transform: scale(0.95);
}
.article-list-item-content {
  height: calc(100% - 1.5rem);
  padding: 0.12rem 0.03rem;
  width: 100%;
  transition: transform 0.5s ease;
  transform-origin: top;
  box-sizing: border-box;
}
.article-list-item-content-title {
  font-size: 0.13rem;
  margin-bottom: 0.06rem;
  -webkit-user-select: none;
          user-select: none;
  text-align: justify;
  color: #3F4E4F;
  text-shadow: 0.01rem 0.01rem 0.05rem rgba(0, 0, 0, 0.1);
  min-height: 0.4rem;
}
.article-list-item-content-title-light {
  color: #ff4848;
}
.article-list-item-content-sub-title {
  font-size: 0.12rem;
  margin-bottom: 0.06rem;
  text-align: justify;
  color: #ccc;
}
.article-list-item-content-create-time {
  text-align: center;
  position: absolute;
  bottom: 0.06rem;
  font-style: italic;
  width: 100%;
  color: #ccc;
  font-size: 0.1rem;
}
@media screen and (max-width: 1000px) {
  .article-list-item {
    height: 2.3rem;
    margin-bottom: 0.12rem !important;
  }
  .article-list-item.normal,
  .article-list-item.lite {
    box-shadow: 0 0 0.1rem 0.05rem rgba(0, 0, 0, 0.1);
  }
  .article-list-item.normal .article-list-item-content,
  .article-list-item.lite .article-list-item-content {
    transform: scale(0.95);
  }
  .article-list-item-content-sub-title {
    display: none;
  }
}

.article-block {
  width: 100%;
  padding: 0.1rem 0.12rem;
  padding-bottom: 0;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  margin: 0.14rem 0;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
}
.article-block-content {
  margin-top: 0.16rem;
}
.article-block .ant-list-grid .ant-col > .ant-list-item {
  margin-bottom: 0;
}
.article-block-item {
  width: 100%;
}
.article-block-item-title {
  font-size: 0.14rem;
  margin-bottom: 0.06rem;
  -webkit-user-select: none;
          user-select: none;
  text-align: justify;
  color: #3F4E4F;
  text-shadow: 0.01rem 0.01rem 0.05rem rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  width: 3rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.article-block-item-sub-title {
  font-size: 0.12rem;
  color: #bbb;
  display: flex;
  justify-content: space-between;
}
.article-block .loading-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.article-block-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.article-block-bg img {
  height: 100%;
}

.extension {
  width: 100%;
  padding: 0.06rem 0.12rem;
  background-color: #454545;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
  overflow: hidden;
  margin-top: 0.14rem;
}
.extension a {
  color: #fff;
  text-decoration: none !important;
}
.extension-title {
  font-size: 0.16rem;
}
.extension-title span {
  font-size: 0.1rem;
  color: rgba(255, 255, 255, 0.5);
}
.extension-tips {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.14rem;
  color: rgba(255, 255, 255, 0.8);
}
.extension-tips .anticon {
  margin-left: 0.06rem;
  font-size: 0.2rem;
}

.extension {
  width: 100%;
  padding: 0.06rem 0.12rem;
  background-color: #454545;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
  overflow: hidden;
  margin-top: 0.14rem;
}
.extension a {
  color: #fff;
  text-decoration: none !important;
}
.extension-title {
  font-size: 0.16rem;
}
.extension-title span {
  font-size: 0.1rem;
  color: rgba(255, 255, 255, 0.5);
}
.extension-tips {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.14rem;
  color: rgba(255, 255, 255, 0.8);
}
.extension-tips .anticon {
  margin-left: 0.06rem;
  font-size: 0.2rem;
}

.extension {
  width: 100%;
  padding: 0.06rem 0.12rem;
  background-color: #fff;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
  overflow: hidden;
  margin-top: 0.14rem;
}
.extension a {
  color: #454545;
  text-decoration: none !important;
}
.extension-title {
  font-size: 0.14rem;
}
.extension-title span {
  font-size: 0.1rem;
  color: rgba(255, 255, 255, 0.5);
}
.extension-tips {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.12rem;
  color: rgba(0, 0, 0, 0.5);
}
.extension-tips .anticon {
  margin-left: 0.06rem;
  font-size: 0.2rem;
}

.expand-image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  min-height: 4rem;
  background-color: #000;
  border-radius: 0.03rem;
}
.expand-image-container img {
  pointer-events: none;
  width: 100%;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.05);
}
.expand-image-container-full {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.8rem;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0.2rem;
  color: #fff;
  text-shadow: 0 0 0.02rem rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-size: 0.14rem;
}
.expand-image-container-full i {
  margin: 0 0.05rem;
  opacity: 0.8;
}
.expand-image-collapse {
  text-align: center;
  padding: 0.1rem;
  color: #666;
  text-shadow: 0 0 0.02rem rgba(0, 0, 0, 0.3);
  font-size: 0.12rem;
  cursor: pointer;
}
.expand-image-collapse i {
  margin: 0 0.05rem;
  opacity: 0.3;
}

.meme-pop-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.08rem 0;
}
.meme-pop-action-create {
  display: flex;
  align-items: center;
}
.meme-pop-action-create i {
  font-size: 0.18rem;
  margin-left: 0.04rem !important;
}

.memes-rank-list-item {
  width: 15%;
  position: relative;
  overflow: hidden;
  transform-origin: center bottom;
  transition: transform 0.3s ease-out, border-radius 0.3s ease-out;
}
.memes-rank-list-item:first-child {
  border-radius: 0.06rem 0 0 0.06rem;
  transform-origin: bottom left;
}
.memes-rank-list-item:last-child {
  border-radius: 0 0.06rem 0.06rem 0;
  transform-origin: bottom right;
}
.memes-rank-list-item:hover {
  transform: scale(1.2);
  border-radius: 0.06rem 0.06rem 0 0;
  z-index: 99;
  box-shadow: 0 0 0.2rem 0.05rem rgba(0, 0, 0, 0.15);
}
.memes-rank-list-item:hover .memes-rank-list-item-mask {
  opacity: 1;
}
.memes-rank-list-item-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-out;
  opacity: 0;
  cursor: pointer;
}
.memes-rank-list-item-mask-user {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0.06rem 0;
  justify-content: center;
  align-items: center;
}
.memes-rank-list-item-mask-user-avatar {
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.06rem;
}
.memes-rank-list-item-mask-user-name {
  font-size: 0.1rem;
  color: rgba(255, 255, 255, 0.6);
  text-shadow: 0 0 0.05rem rgba(0, 0, 0, 0.3);
  max-width: 0.75rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.memes-rank-list-item-mask-body {
  font-size: 0.12rem;
  color: #fff;
  text-shadow: 0 0 0.05rem rgba(255, 255, 255, 0.5);
  text-align: center;
}
.memes-rank-list-item-pic {
  width: 100%;
  height: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.memes-rank-list-item-pic img {
  max-width: 100%;
  /* 图片最大宽度不超过容器宽度 */
  max-height: 100%;
  /* 图片最大高度不超过容器高度 */
  height: auto;
  /* 根据宽高比自动调整高度 */
  width: auto;
  /* 根据宽高比自动调整宽度 */
  pointer-events: none;
  z-index: 2;
}
.memes-rank-list-item-pic-img-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-backdrop-filter: blur(0.2rem);
          backdrop-filter: blur(0.2rem);
}

.memes-rank {
  margin-top: 0.12rem;
}
.memes-rank.block {
  background-color: #fff;
  border-radius: 0.03rem;
  padding: 0.1rem 0.12rem;
}
.memes-rank-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.06rem;
  height: 1.3rem;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: 0.12rem;
  position: relative;
}
.memes-rank-list-loading {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 0.16rem;
  color: #fff;
  text-shadow: 0 0 0.03rem rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.memes-rank-list-bgtext {
  font-size: 0.72rem;
  width: 100%;
  height: 100%;
  /* text-align: center; */
  font-family: 'bailubangbangshouxieti';
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.03);
  font-style: italic;
  position: absolute;
  left: 0;
  top: 0;
}

.lunar {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.lunar.home {
  height: 1.55rem;
}
.lunar-content {
  padding: 0.12rem 0.12rem;
  background-color: #fff;
  border-radius: 0.03rem;
  margin-bottom: 0.1rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
  transition: all 0.3s ease-in;
  cursor: pointer;
  width: 48.5%;
}
.lunar-content:hover {
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.1);
}
.lunar-content.more {
  background-color: #454545;
}
.lunar-content.more:hover {
  background-color: #5f5f5f;
}
.lunar-content.more .lunar-content-name span {
  color: #fff;
}
.lunar-content.more .lunar-content-tips .anticon {
  color: #fff;
}
.lunar-content-name {
  font-size: 0.12rem;
  text-align: center;
  font-weight: 300;
  color: #888;
  font-style: italic;
}
.lunar-content-name span {
  color: #333;
  font-weight: 700;
  margin-right: 0.03rem;
  font-size: 0.14rem;
  font-style: normal;
}
.lunar-content-tips {
  text-align: center;
}
.lunar-content-tips .anticon {
  font-size: 0.16rem;
}
@media screen and (max-width: 1000px) {
  .lunar {
    padding: 0 0.12rem;
    margin-bottom: -0.12rem;
  }
  .lunar.home {
    height: auto;
  }
}

.quan {
  width: 100%;
  padding: 0.1rem 0.12rem;
  padding-bottom: 0;
  margin: 0.14rem 0;
  overflow: visible;
  background-color: #fff;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
}
.quan-container {
  margin-top: 0.2rem;
  height: 3.18rem;
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
  overflow-y: hidden;
}
.quan-container-content {
  width: 21.08rem;
}
.quan-container-content-card {
  border-radius: 0.03rem;
  background-color: #fff;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.03);
  width: 2rem;
  height: 3.08rem;
  overflow: visible;
  margin-right: 0.12rem;
  display: inline-block;
  vertical-align: top;
}
.quan-container-content-card:last-child {
  margin-right: 0;
}

.home {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.home .ad {
  height: 1rem;
}
.home .ad .carousel-item {
  height: 1rem;
}
.home-content {
  min-height: 6rem;
  width: 100%;
  background-color: #fff;
  padding: 0.12rem 0.12rem 0.2rem 0.12rem;
  box-sizing: border-box;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
}
.home-content .ant-list-grid .ant-col > .ant-list-item {
  margin-bottom: 0.12rem;
}
.home-content-list {
  margin-top: 0.2rem;
}
.home .loading-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.home-bg img {
  height: 100%;
}

.article-list-top {
  background-color: #fff;
  margin-bottom: 0.12rem;
  padding: 0.12rem;
  box-sizing: border-box;
}
.article-list .loading-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.article-list-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.article-list-bg img {
  height: 100%;
}
.article-list-search {
  width: 2rem;
  position: absolute;
  right: 0.12rem;
}
.article-list-search input {
  border-radius: 0.3rem;
}

.wenan-card2 {
  margin-bottom: 0.12rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.03);
  padding: 0.12rem 0.15rem;
  transition: all 0.3s ease-out;
  border-radius: 0.03rem;
}
.wenan-card2:last-child {
  margin-bottom: 0;
}
.wenan-card2:hover {
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.1);
}
.wenan-card2:hover .wenan-card2-action {
  opacity: 1;
}
.wenan-card2:hover .wenan-card2-content {
  text-shadow: 0.02rem 0.02rem 0.03rem rgba(0, 0, 0, 0.2);
}
.wenan-card2-user {
  border-bottom: 0.01rem solid #f8f8f8;
  padding-bottom: 0.12rem;
}
.wenan-card2-user-nickname {
  margin-left: 0.08rem;
  color: #888;
}
.wenan-card2-user-action {
  margin-left: 0.08rem;
  font-size: 0.12rem;
  color: #ccc;
}
.wenan-card2-user-time {
  color: #ccc;
  font-size: 0.11rem;
}
.wenan-card2-user.hide .wenan-card2-user-nickname,
.wenan-card2-user.hide .user-avatar {
  display: none;
}
.wenan-card2-content {
  padding: 0.4rem;
  font-size: 0.16rem;
  font-weight: 300;
  color: #888;
  transition: all 0.3s ease-out;
  -webkit-user-select: none;
          user-select: none;
  line-height: 0.28rem;
  letter-spacing: 0.03rem;
}
.wenan-card2-content-pic {
  margin-top: 0.2rem;
  display: flex;
  justify-content: center;
}
.wenan-card2-content-pic img {
  width: 2rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.1);
}
.wenan-card2-content-thumb {
  margin-top: 0.24rem;
  width: 1rem;
  position: relative;
}
.wenan-card2-content-thumb img {
  width: 100%;
  height: auto;
  cursor: pointer;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: all 0.5s ease;
}
.wenan-card2-content-thumb:hover img {
  opacity: 0.9;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.2);
}
.wenan-card2-content-thumb:hover .wenan-card2-content-thumb-tip {
  opacity: 1;
}
.wenan-card2-content-thumb-tip {
  position: absolute;
  padding: 0.08rem;
  cursor: pointer;
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 0.16rem);
  left: calc(0.5rem - 0.16rem);
  transition: opacity 0.5s ease-in;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.wenan-card2-content-thumb-tip i {
  color: #fff;
  font-size: 0.2rem;
}
.wenan-card2-action {
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.wenan-card2-action button {
  font-size: 0.13rem;
}
.wenan-card2-action-btn {
  font-size: 0.14rem;
}
@media screen and (max-width: 1000px) {
  .wenan-card2-content {
    padding: 0.24rem 0;
    font-size: 0.14rem;
  }
}

.quan-list-content-card {
  border-radius: 0.03rem;
  background-color: #fff;
  width: 100%;
  height: 3.08rem;
  overflow: visible;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0.12rem;
}
.quan-list-content-card:last-child {
  margin-right: 0;
}
.quan-list .loading-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quan-list-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.quan-list-bg img {
  height: 100%;
}
@media screen and (max-width: 1000px) {
  .quan-list .quan-item-content {
    padding: 0.3rem 0.2rem;
  }
}

.wenan-item {
  width: 100%;
  animation: fade-in-key 0.5s linear 1;
}
.wenan-item-word {
  font-size: 0.14rem;
  margin-bottom: 0.32rem;
  -webkit-user-select: none;
          user-select: none;
  text-align: justify;
  color: #566a6b;
  font-weight: 300;
  text-shadow: 0.01rem 0.01rem 0.03rem rgba(0, 0, 0, 0.2);
}
.wenan-item-word-light {
  color: #A27B5C;
}
.wenan-item-article {
  font-size: 0.12rem;
  margin-bottom: 0.12rem;
}
.wenan-item-article a {
  color: #bbb;
  transition: all 0.3s linear;
}
.wenan-item-article a span {
  color: #bea28d;
}
.wenan-item-article a:hover {
  color: #888;
  text-decoration: underline;
}
.wenan-item:hover .wenan-item-action {
  opacity: 1;
}
.wenan-item-action {
  font-size: 0.12rem;
  color: #ccc;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.wenan-item-action-item {
  cursor: pointer;
  transition: color 0.3s ease-in;
  margin-left: 0.24rem;
}
.wenan-item-action-item span {
  margin-left: 0.05rem;
}
.wenan-item-action-item:first-child {
  margin-left: 0;
}
@media screen and (max-width: 1000px) {
  .wenan-item-action {
    opacity: 1;
  }
}

.kw-roll {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0 0.24rem;
  background-color: #fff;
  border-radius: 0.03rem;
}
.kw-roll .ant-radio-group .ant-radio-button-wrapper {
  font-size: 0.12rem;
  border-color: #f5f5f6;
  color: #999;
}
.kw-roll .ant-radio-group .ant-radio-button-wrapper:hover {
  color: #454545;
}
.kw-roll .ant-radio-group .ant-radio-button-wrapper:focus {
  box-shadow: none;
  outline: none;
}
.kw-roll .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: #454545;
  background-color: #fff;
  color: #454545;
  box-shadow: -0.01rem 0 0 0 #454545;
}
.kw-roll .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked::before {
  display: none;
}
.kw-roll-content {
  min-height: 2.57rem;
  font-weight: 200;
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kw-roll-content-total {
  color: #ccc;
  font-size: 0.12rem;
}
.kw-roll-content-total span {
  color: #888;
  font-weight: 700;
  margin: 0 0.03rem;
}
.kw-roll-content-word {
  -webkit-user-select: none;
          user-select: none;
  color: #3F4E4F;
  text-align: justify;
  font-size: 0.16rem;
  line-height: 0.28rem;
  letter-spacing: 0.03rem;
  text-shadow: 0.01rem 0.01rem 0.05rem rgba(0, 0, 0, 0.1);
  font-family: Arial;
  min-height: calc(2.3rem - 0.7rem);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.12rem;
  margin: 0.12rem 0;
  width: 100%;
}
.kw-roll-content-word-light {
  color: #FF6000;
}
.kw-roll-content-word-author {
  font-weight: 700;
}
.kw-roll-content-word-author a {
  color: #FF6000 !important;
  transition: opacity 0.3s ease-out;
}
.kw-roll-content-word-author a:hover {
  color: #FF6000 !important;
  opacity: 0.7;
  -webkit-text-decoration-color: #FF6000;
          text-decoration-color: #FF6000;
}
.kw-roll-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.2rem;
}
.kw-roll-action-article {
  font-size: 0.1rem;
  color: #ccc;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.kw-roll-action-article.loading {
  opacity: 0;
}
.kw-roll-action-article a {
  font-size: 0.11rem;
  color: #bbb;
}
@media screen and (max-width: 1000px) {
  .kw-roll {
    padding: 0;
    width: 100%;
  }
  .kw-roll .kw-roll-content {
    padding: 0;
  }
}

.keywords {
  padding-bottom: 0 !important;
}
.keywords-title {
  font-weight: 300;
  text-align: center;
  font-family: Arial;
  color: #ccc;
  font-style: italic;
  text-shadow: 0.05rem 0.05rem 0.02rem rgba(0, 0, 0, 0.05);
}
.keywords-action-container {
  padding: 0 0.3rem;
  color: #ccc;
  font-size: 0.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.keywords-action-container .ant-switch-checked {
  background-color: #454545;
}
.keywords-condition {
  margin: 0.2rem 0.25rem 0.1rem 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.03);
  padding: 0 0.1rem 0 0;
  height: 0.45rem;
  border-radius: 0.3rem;
  position: relative;
}
.keywords-condition-clear {
  position: absolute;
  right: -0.25rem;
  color: #ccc;
  cursor: pointer;
  transition: color 0.5s ease;
}
.keywords-condition-clear:hover {
  color: #A27B5C;
}
.keywords-condition .tag-container {
  width: 85%;
  overflow-x: auto;
  padding: 0.09rem 0.12rem;
}
.keywords-condition .tag-container input {
  height: 0.265rem;
}
.keywords-condition .tag-container-content {
  white-space: nowrap;
}
.keywords-condition .tag-container-content .ant-tag {
  padding: 0.03rem 0.12rem;
  height: auto;
  border-radius: 0.2rem;
  color: #aaa;
  background-color: #fff;
  margin-right: 0.12rem;
  border-color: transparent;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.05);
}
.keywords-condition .tag-container-content .ant-tag:last-child {
  border-color: #e5e5e6;
  transition: all 0.5s ease;
}
.keywords-condition .tag-container-content .ant-tag:last-child i {
  color: #ccc;
  transition: color 0.5s ease;
}
.keywords-condition .tag-container-content .ant-tag:last-child:hover {
  color: #333;
  border-color: #333;
}
.keywords-condition .tag-container-content .ant-tag:last-child:hover i {
  color: #333;
}
.keywords-condition .tag-container-content .ant-tag i {
  color: #ccc;
  transition: color 0.5s ease;
}
.keywords-condition .tag-container-content .ant-tag i:hover {
  color: #333;
}
.keywords-content {
  padding: 0.32rem 0;
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  overflow: visible;
}
.keywords-content-card {
  border-radius: 0.03rem;
  background-color: #fff;
  width: 2rem;
  height: 2.28rem;
  overflow: visible;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0.12rem;
  position: relative;
  left: calc(50% - 1rem);
}
.keywords-content-card:last-child {
  margin-right: 0;
}
.keywords .loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .keywords-action-container {
    padding: 0 0.1rem;
  }
  .keywords-content {
    padding: 0.3rem 0;
  }
  .keywords-condition {
    padding: 0.06rem 0.06rem;
    margin: 0.2rem 0 0.1rem 0;
    height: auto !important;
  }
  .keywords-condition .tag-container {
    padding: 0 0.06rem;
    height: auto !important;
  }
  .keywords-condition .tag-container-content .ant-tag {
    padding: 0 0.06rem;
    box-shadow: none;
    border: 0.01rem solid #f5f5f6;
  }
  .keywords-condition-clear {
    display: none;
  }
  .keywords-condition .ant-btn-primary {
    padding: 0 0.06rem !important;
    font-size: 0.14rem;
    height: auto !important;
  }
  .keywords-content-card {
    width: 100% !important;
    left: 0 !important;
  }
}

.user-center {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  line-height: 0.28rem;
}
.user-center .loading-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-center h2 {
  margin-top: 0.3rem;
}
.user-center-head {
  padding: 0.5rem 0;
  min-height: 2rem;
  border-radius: 0.03rem;
  background: linear-gradient(60deg, #543ab7 0%, #00acc1 100%);
  color: white;
  position: relative;
}
.user-center-head .waves {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.5rem;
  margin-bottom: -0.15rem;
  /*Fix for safari gap*/
}
.user-center-head-avatar,
.user-center-head-nickname {
  display: flex;
  justify-content: center;
}
.user-center-head-avatar .user-avatar {
  box-shadow: 0 0 0.1rem 0.03rem rgba(0, 0, 0, 0.3);
}
.user-center-head-nickname {
  font-size: 0.16rem;
  margin-top: 0.06rem;
  color: #fff;
  text-shadow: 0.01rem 0.01rem 0.05rem rgba(0, 0, 0, 0.5);
}
.user-center-body {
  margin-top: 0.5rem;
}
.user-center-body-tab {
  padding: 0.3rem 0.12rem 0.25rem 0.12rem;
  box-sizing: border-box;
  min-height: 5rem;
}
.user-center-body-tab-creator {
  text-align: right;
  margin: 0 0.5rem;
  padding: 0.12rem;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.03);
  transition: box-shadow 0.3s ease-in;
}
.user-center-body-tab-creator:hover {
  box-shadow: 0 0 0.08rem 0.03rem rgba(0, 0, 0, 0.2);
}
.user-center-body-tab-creator textarea {
  border: none;
  border-bottom: 0.01rem solid #f5f5f6;
  box-shadow: none !important;
  margin-bottom: 0.12rem;
  border-radius: 0 !important;
  padding: 0 0.12rem;
  line-height: 0.28rem;
  letter-spacing: 0.03rem;
}
.user-center-body-tab-creator textarea:active,
.user-center-body-tab-creator textarea:hover,
.user-center-body-tab-creator textarea:focus {
  border-color: #2C3333;
}
.user-center-body-tab-creator input {
  width: 3rem;
  border: none;
  box-shadow: none !important;
  padding: 0;
}
.user-center-body-tab-empty {
  text-align: center;
  color: #2C3333;
  font-size: 0.16rem;
  font-weight: 300;
  margin: 0.3rem 0 1rem 0;
}
.user-center-body-tab-card {
  border-radius: 0.03rem;
  background-color: #fff;
  width: 2rem;
  height: 2.28rem;
  overflow: visible;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0.12rem;
  position: relative;
  left: calc(50% - 1rem);
}
.user-center-body-tab-card:last-child {
  margin-right: 0;
}
.user-center-body .ant-tabs-bar {
  border-bottom: none !important;
}
.user-center-body .ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-center-body .ant-tabs-nav-scroll .ant-tabs-tab {
  color: #bbb;
  transition: all 0.3s ease;
}
.user-center-body .ant-tabs-nav-scroll .ant-tabs-tab.ant-tabs-tab-active {
  color: #2C3333 !important;
  font-weight: 700 !important;
  text-shadow: 0.01rem 0.01rem 0.03rem rgba(0, 0, 0, 0.3);
}
.user-center-body .ant-tabs-nav-scroll .ant-tabs-ink-bar {
  width: 0.3rem !important;
  height: 0.03rem;
  position: relative;
  border-radius: 0.05rem;
  left: 0.305rem;
  background-color: #2C3333;
}
/* Animation */
.parallax > use {
  animation: move-forever 20s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 5s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 9s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 11s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 15s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-0.9rem, 0, 0);
  }
  100% {
    transform: translate3d(0.85rem, 0, 0);
  }
}
@keyframes shadow-breath {
  0%,
  100% {
    box-shadow: 0 0 0.3rem 0.01rem #375f5f;
  }
  50% {
    box-shadow: 0 0 0.03rem 0.01rem #375f5f;
  }
}
@media (max-width: 768px) {
  .waves {
    display: none;
  }
  .user-center-body-tab-creator {
    margin: 0;
  }
}

.poster-tools {
  margin-bottom: 0.2rem;
}
.poster-tools-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.poster-tools-content-name {
  font-size: 0.12rem;
  color: #333;
  font-weight: 300;
  font-family: arial;
}
.poster-tools-content-action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.poster-tools-content-action-item {
  font-size: 0.16rem;
  color: #aaa;
  transition: color 0.3s ease;
  margin-right: 0.2rem;
  cursor: pointer;
  font-family: arial;
  font-weight: 300;
}
.poster-tools-content-action-item.active {
  color: #333;
  font-weight: 400;
}
.poster-tools-content-action-item.pic img {
  width: 0.2rem;
  opacity: 0.4;
  transition: opacity 0.3s ease;
}
.poster-tools-content-action-item.pic.active img {
  opacity: 1 ;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.1);
}

.poster.loading .poster-container-view-photo {
  animation-name: post-breath;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.poster-container {
  height: 5rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  box-shadow: 0 0 0.1rem 0.05rem rgba(0, 0, 0, 0.3);
}
.poster-container-guide {
  position: absolute;
  right: 0.2rem;
  bottom: 0.2rem;
  display: none;
}
.poster-container-guide-qrcode {
  height: 1.1rem;
  width: 1.1rem;
  box-sizing: border-box;
  padding: 0.06rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.1rem;
}
.poster-container-guide-qrcode img {
  width: 1rem;
}
.poster-container-guide-tips {
  color: rgba(255, 255, 255, 0.3);
  font-style: italic;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.06rem;
}
.poster-container-view {
  width: 4rem;
  height: 5rem;
  background-color: #888;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.3s ease;
}
.poster-container-view-photo {
  width: 4rem;
  height: 5rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  transition: all 0.3s ease;
  z-index: 1;
  overflow: hidden;
}
.poster-container-view-photo-bg {
  transition: opacity 0.3s ease;
  width: 100%;
  height: 100%;
}
.poster-container-view-photo-bg img {
  display: block;
  width: 100%;
  position: absolute;
}
.poster-container-view-word {
  position: absolute;
  height: 2rem;
  width: 4rem;
  padding: 0.15rem 0.2rem;
  box-sizing: border-box;
  background-color: #fff;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease;
  z-index: 10;
  text-align: justify;
  line-height: 0.3rem;
  letter-spacing: 0.01rem;
  overflow: hidden;
}
.poster-container-view-word-sy {
  position: absolute;
  left: calc(50% - 0.3rem);
  bottom: 0.1rem;
  opacity: 0.4;
  width: 0.6rem;
}
.poster-container-view.font-style-normal .poster-container-view-word {
  font-style: normal;
}
.poster-container-view.font-style-italic .poster-container-view-word {
  font-style: italic;
}
.poster-container-view.white .poster-container-view-word {
  color: #333;
  background-color: #fff;
}
.poster-container-view.black .poster-container-view-word {
  color: #fff;
  background-color: #000;
}
.poster-container-view.full-rect .poster-container-view-word,
.poster-container-view.full-square .poster-container-view-word {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0.1rem;
  margin: 0.12rem;
  width: calc(4rem - 0.24rem);
}
.poster-container-view.full-rect.white .poster-container-view-word,
.poster-container-view.full-square.white .poster-container-view-word {
  color: #333;
  background-color: rgba(255, 255, 255, 0.7);
}
.poster-container-view.full-rect.black .poster-container-view-word,
.poster-container-view.full-square.black .poster-container-view-word {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
}
.poster-container-view.full-square {
  height: 4rem;
}
.poster-container-view.full-square .poster-container-view-photo {
  height: 4rem;
}
.poster-container-view.full-square .poster-container-view-word {
  height: 1.5rem;
  font-size: 0.13rem;
}
.poster-container-view.half-rect .poster-container-view-word,
.poster-container-view.half-square .poster-container-view-word {
  background-color: #fff;
  border-radius: 0;
}
.poster-container-view.half-rect.white .poster-container-view-word,
.poster-container-view.half-square.white .poster-container-view-word {
  color: #333;
  background-color: #fff;
}
.poster-container-view.half-rect.black .poster-container-view-word,
.poster-container-view.half-square.black .poster-container-view-word {
  color: #fff;
  background-color: #000;
}
.poster-container-view.half-rect .poster-container-view-photo {
  height: 3rem;
}
.poster-container-view.half-square {
  height: 4rem;
}
.poster-container-view.half-square .poster-container-view-photo {
  height: 2.5rem;
}
.poster-container-view.half-square .poster-container-view-word {
  height: 1.5rem;
  line-height: 0.28rem;
}
.poster-tool-keywords {
  margin: 0.12rem 0;
}
.poster-tool-keywords-title {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.14rem;
  margin-bottom: 0.12rem;
  line-height: 0.265rem;
}
.poster-tool-keywords .tag-container {
  overflow-x: auto;
}
.poster-tool-keywords .tag-container input {
  height: 0.28rem;
  margin-bottom: 0.12rem;
}
.poster-tool-keywords .tag-container-content {
  white-space: pre-wrap;
}
.poster-tool-keywords .tag-container-content .ant-tag {
  padding: 0.03rem 0.12rem;
  height: auto;
  border-radius: 0.2rem;
  color: #aaa;
  background-color: rgba(0, 0, 0, 0.03);
  margin-right: 0.12rem;
  margin-bottom: 0.12rem;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.4);
}
.poster-tool-keywords .tag-container-content .ant-tag:last-child {
  border-color: rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
}
.poster-tool-keywords .tag-container-content .ant-tag:last-child i {
  color: rgba(0, 0, 0, 0.4);
  transition: color 0.5s ease;
}
.poster-tool-keywords .tag-container-content .ant-tag:last-child:hover {
  color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}
.poster-tool-keywords .tag-container-content .ant-tag:last-child:hover i {
  color: rgba(0, 0, 0, 0.8);
}
.poster-tool-keywords .tag-container-content .ant-tag i {
  color: rgba(0, 0, 0, 0.4);
  transition: color 0.5s ease;
}
.poster-tool-keywords .tag-container-content .ant-tag i:hover {
  color: rgba(0, 0, 0, 0.8);
}
.poster-tool-keywords-item {
  border-radius: 0.3rem;
  padding: 0.03rem 0.12rem;
  font-size: 0.12rem;
  background-color: rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.5);
}
.poster-tool-keywords-tips {
  color: #fff;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.06rem;
}
.poster-tool-action {
  margin: 0.3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.poster-tool-action button {
  margin: 0 0.12rem;
}
.poster-tool-tips {
  color: orange;
  text-align: center;
  font-size: 0.1rem;
  margin-top: 0.12rem;
  display: none;
}
.poster-tool-tips.tongzhi {
  background-color: orange;
  color: #fff;
  margin-top: 0;
  font-size: 0.14rem;
  padding: 0.03rem 0.12rem;
  font-weight: 700;
  display: none;
}
.poster-tool-container {
  padding: 0.2rem 2rem 0.5rem 2rem;
}
@keyframes post-breath {
  0% {
    background-color: rgba(0, 0, 0, 0.7);
  }
  100% {
    background-color: #000000;
  }
}
@media screen and (max-width: 1000px) {
  .poster-container-guide,
  .poster-container-keywords {
    display: none;
  }
  .poster-tool-tips {
    display: block;
  }
  .poster-container {
    background-color: transparent;
    height: auto;
  }
  .poster-container-view {
    background-color: transparent;
  }
  .poster-container-view.full-rect .poster-container-view-word,
  .poster-container-view.full-square .poster-container-view-word {
    width: calc(100% - 0.24rem);
  }
  .poster-container-view.full-rect .poster-container-view-photo,
  .poster-container-view.full-square .poster-container-view-photo {
    width: 100%;
  }
  .poster-container-view.full-react {
    height: 100%;
  }
  .poster-container-view.full-react .poster-container-view-photo {
    width: 100%;
  }
  .poster-container-view.full-react .poster-container-view-word {
    width: 100%;
  }
  .poster-container-view.full-square {
    height: 4rem;
  }
  .poster-container-view.full-square .poster-container-view-photo {
    height: 4rem;
    width: 100%;
  }
  .poster-container-view.half-rect .poster-container-view-photo {
    height: 3rem;
    width: 100%;
  }
  .poster-container-view.half-rect .poster-container-view-word {
    width: 100%;
  }
  .poster-container-view.half-square {
    height: 4rem;
  }
  .poster-container-view.half-square .poster-container-view-photo {
    height: 2.5rem;
    width: 100%;
  }
  .poster-container-view.half-square .poster-container-view-word {
    width: 100%;
  }
  .poster-tool-container {
    padding: 0.2rem 0.6rem;
  }
  .poster-tool-container {
    padding: 0.12rem 0;
  }
}

.warning {
  min-height: 6rem;
  width: 100%;
  padding: 10%;
  box-sizing: border-box;
}
.warning-icon {
  text-align: center;
}
.warning-icon img {
  width: 0.8rem;
}
.warning-title {
  font-size: 0.22rem;
  text-align: center;
  margin: 0.2rem 0;
}
.warning-option {
  font-size: 0.16rem;
  color: #888;
  text-align: center;
}
.warning-option a {
  color: #888;
  text-decoration: underline;
}
.warning-option a:hover {
  color: #ff4848;
}

.parsing-action {
  display: flex;
  justify-content: center;
}
.parsing-layer {
  background-color: #fff;
  margin-bottom: 0.12rem;
}
.parsing-layer:first-child {
  padding: 0.3rem 1rem;
}
.parsing-title {
  font-weight: 300;
  text-align: center;
  font-family: Arial;
  color: #ccc;
  font-style: italic;
  text-shadow: 0.05rem 0.05rem 0.02rem rgba(0, 0, 0, 0.05);
}
.parsing-fenxi {
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin: 0.5rem 0 0 0;
  width: 100%;
}
.parsing-fenxi-item:first-child {
  width: 65%;
}
.parsing-fenxi-item:last-child {
  width: 30%;
}
.parsing-fenxi-item-tags {
  padding: 0.2rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.parsing-fenxi-item-tag {
  padding: 0.03rem 0.12rem;
  background-color: #f5f5f6;
  color: #888;
  border-radius: 0.3rem;
  margin-right: 0.16rem;
  margin-bottom: 0.16rem;
  font-size: 0.12rem;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.parsing-fenxi-item-tag:hover {
  color: #555;
  background-color: #e5e5e6;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.05);
}
.parsing-fenxi-item-sentiment {
  padding: 0.2rem 0;
  font-size: 0.18rem;
  text-align: center;
  border-radius: 0.05rem;
  font-style: italic;
  font-weight: 700;
  transition: all 1s ease-in;
  background-color: #fff;
}
.parsing-fenxi-item-sentiment.positive {
  color: #FF6D28;
  background-color: #ffd5c1;
}
.parsing-fenxi-item-sentiment.negative {
  color: #4B56D2;
  background-color: #c5c9f1;
}
.parsing-fenxi-item-sentiment.neutral {
  color: #227C70;
  background-color: #3bc9b6;
}
.parsing-content {
  padding: 0 0.24rem;
  min-height: 2.57rem;
  font-weight: 200;
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}
.parsing-content-word {
  -webkit-user-select: none;
          user-select: none;
  color: #3F4E4F;
  text-align: justify;
  font-size: 0.16rem;
  line-height: 0.28rem;
  letter-spacing: 0.03rem;
  text-shadow: 0.01rem 0.01rem 0.05rem rgba(0, 0, 0, 0.1);
  font-family: Arial;
  min-height: calc(2.3rem - 0.7rem);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  padding: 0.12rem;
  width: 100%;
}
.parsing-content-word-author {
  font-weight: 700;
}
.parsing-content-word-author a {
  color: #15878b !important;
  transition: opacity 0.3s ease-out;
}
.parsing-content-word-author a:hover {
  color: #15878b !important;
  opacity: 0.7;
  -webkit-text-decoration-color: #15878b;
          text-decoration-color: #15878b;
}
@media screen and (max-width: 1000px) {
  .parsing {
    padding: 0.1rem;
  }
  .parsing-layer:first-child {
    padding: 0.12rem 0.12rem;
  }
  .parsing-fenxi {
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .parsing-fenxi-item:first-child {
    width: 100%;
  }
  .parsing-fenxi-item:last-child {
    width: 100%;
    margin-bottom: 0.2rem;
  }
  .roll-content-word {
    font-size: 0.14rem;
  }
}

.mask-container {
  position: fixed; }

.mask-container .mask {
  position: absolute; }

.mask-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: hidden; }
.mask-container .mask {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0; }
.mask-container .mask.fade-in {
  opacity: 0.3;
}
.mask-container .content {
  /* height: 100%;
  width: 100%; */
  /* position: absolute;
  z-index: 2; */
}

.active-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  animation-name: key-fade-in;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
.active-box-content {
  max-width: 5rem;
  padding: 0 0.12rem;
  position: relative;
  text-align: center;
}
.active-box-content img {
  width: 100%;
}
.active-box-content-copy {
  width: 4.5rem;
  height: 0.6rem;
  position: absolute;
  right: 0.3rem;
  top: 1.3rem;
  cursor: pointer;
}
.active-box-content-close {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  cursor: pointer;
  top: 0;
  right: 0.1rem;
}
.active-box-content-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  width: 100%;
  padding: 0.27rem;
}
.active-box-content-action-item {
  width: 1.38rem;
  height: 0.8rem;
  cursor: pointer;
}
.qrcode {
  width: 2.5rem;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
  background-color: #fff;
  padding: 0.06rem;
  border-radius: 0.12rem;
  left: calc(50% - 1.25rem);
  top: calc(50% - 2rem);
  text-align: center;
  position: absolute;
  z-index: 999;
}
.qrcode-action {
  padding: 0.12rem 0.15rem;
}
.qrcode-tips {
  font-size: 0.14rem;
  text-align: center;
  color: #07c160;
  font-weight: 700;
  padding: 0 0.12rem 0.12rem 0;
}
.qrcode img {
  width: 100%;
}
@keyframes key-fade-in {
  0% {
    transform: translateY(-0.6rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@media screen and (max-width: 1000px) {
  .active-box-content img {
    width: 100%;
  }
  .active-box-content-copy {
    top: 1rem;
    height: 0.4rem;
    right: 0;
  }
  .active-box-content-close {
    right: 0.1rem;
  }
  .active-box-content-action {
    bottom: -0.2rem;
  }
  .active-box-content-action-item {
    width: 1rem;
    height: 0.6rem;
  }
}

.gpt3 .ant-input-search .ant-input-wrapper {
  border-bottom: 0.01rem solid #eee;
  transition: border-color 0.3s ease-out;
}
.gpt3 .ant-input-search .ant-input-wrapper:hover {
  border-color: #2C3333;
}
.gpt3 .ant-input-search input {
  border: none !important;
  border-radius: 0;
  padding-right: 0 !important;
  text-align: center;
}
.gpt3 .ant-input-search input:focus {
  box-shadow: none;
}
.gpt3 .ant-input-search .ant-input-prefix {
  left: 0;
}
.gpt3 .ant-input-search .ant-input-prefix img {
  width: 0.25rem;
}
.gpt3 .ant-input-search .ant-btn {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
  padding: 0;
  text-align: right;
  width: 0.5rem;
  opacity: 0.5;
}
.gpt3 .ant-input-search .ant-btn:hover {
  opacity: 1;
}
.gpt3 .ant-input-search .ant-btn img {
  width: 0.2rem;
}
.gpt3-condition {
  margin-top: 0.24rem;
  font-size: 0.12rem;
}
.gpt3-condition span {
  margin-right: 0.06rem;
  color: #aaa;
}
.gpt3-condition .ant-select {
  font-size: 0.12rem;
  width: 0.8rem;
}
.gpt3-condition .ant-select .ant-select-selection-selected-value {
  padding: 0;
}
.gpt3-condition .ant-select-arrow {
  right: 0;
  margin-right: 0;
}
.gpt3-condition .ant-select-selection {
  border: none;
  border-radius: 0;
  border-bottom: 0.01rem solid #eee;
}
.gpt3-condition-item {
  margin-right: 0.12rem;
  margin-bottom: 0.12rem;
}
.gpt3-condition-item.switch {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.02rem 0.03rem;
  border-radius: 0.15rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.02rem;
  transition: background-color 0.3s ease-out;
}
.gpt3-condition-item.switch span {
  color: #fff;
  padding-left: 0.05rem;
  transform: scale(0.9);
  font-weight: 700;
  margin-right: 0.03rem;
}
.gpt3-condition-item.active {
  background-color: rgba(21, 135, 139, 0.65);
}
.gpt3 h2 {
  display: flex;
  align-items: center;
}
.gpt3 h2 button {
  margin-right: 0.12rem;
}
.gpt3 h2 strong {
  margin: 0 0.06rem;
  color: #ff4848;
}
.gpt3 .answer-status {
  margin-top: 0.12rem;
  padding: 0.06rem 0.12rem;
  background-color: #525252;
  border-radius: 0.03rem 0.03rem 0 0;
  font-size: 0.12rem;
  color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.3s ease-out;
  text-align: center;
}
.gpt3 .answer-status a {
  color: #fff;
  text-decoration: underline;
  text-underline-offset: 0.03rem;
  margin-left: 0.03rem;
}
.gpt3 .answer-status.loading {
  background: linear-gradient(132deg, rgba(53, 55, 64, 0.95), rgba(53, 55, 64, 0.95), rgba(53, 55, 64, 0.7), rgba(53, 55, 64, 0.8), rgba(53, 55, 64, 0.95));
  background-size: 400% 400%;
  animation: Gradient 10s ease infinite;
}
.gpt3 .answer {
  min-height: 3rem;
  width: 100%;
  background-color: #454545;
  border-radius: 0 0 0.03rem 0.03rem;
  padding: 0.24rem 0.24rem 0.48rem 0.24rem;
  position: relative;
  margin-bottom: 0.12rem;
}
.gpt3 .answer pre {
  font-size: 0.16rem;
  color: #fff;
  white-space: pre-wrap;
  line-height: 0.32rem;
  -webkit-user-select: none;
          user-select: none;
  margin-bottom: 0;
}
.gpt3 .answer pre.cursor:after {
  content: "|";
  opacity: 0;
  font-weight: 700;
  color: #FFF;
  animation: cursor 1s infinite;
}
.gpt3 .answer-count {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.03rem 0.12rem;
  padding-left: 0.08rem;
  padding-right: 0.06rem;
  border-radius: 0.06rem 0 0 0;
  justsify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.gpt3 .answer-count span {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.12rem;
}
.gpt3 .answer-count-icon {
  width: 0.16rem;
  margin-right: 0.06rem;
  position: relative;
  top: -0.01rem;
  opacity: 0.7;
}
.gpt3 .answer .mp-guide {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.03rem 0.06rem;
  border-radius: 0 0.06rem 0 0;
  background-color: rgba(255, 255, 255, 0.1);
}
.gpt3 .answer .mp-guide img {
  width: 0.2rem;
  opacity: 0.5;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
}
.gpt3 .answer .mp-guide img:hover {
  opacity: 1;
}
.gpt3-btn-group {
  margin: 0 0 0.24rem 0;
}
@keyframes cursor {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes breath {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.active-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  animation-name: key-fade-in;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
.active-box-content {
  max-width: 5rem;
  padding: 0 0.12rem;
  position: relative;
  text-align: center;
}
.active-box-content img {
  width: 100%;
}
.active-box-content-copy {
  width: 4.5rem;
  height: 0.6rem;
  position: absolute;
  right: 0.3rem;
  top: 1.3rem;
  cursor: pointer;
}
.active-box-content-close {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  cursor: pointer;
  top: 0;
  right: 0.1rem;
}
.active-box-content-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  width: 100%;
  padding: 0.27rem;
}
.active-box-content-action-item {
  width: 1.38rem;
  height: 0.8rem;
  cursor: pointer;
}
.qrcode {
  width: 2.5rem;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
  background-color: #fff;
  padding: 0.06rem;
  border-radius: 0.12rem;
  left: calc(50% - 1.25rem);
  top: calc(50% - 2rem);
  text-align: center;
  position: absolute;
  z-index: 999;
}
.qrcode-action {
  padding: 0.12rem 0.15rem;
}
.qrcode-tips {
  font-size: 0.14rem;
  text-align: center;
  color: #07c160;
  font-weight: 700;
  padding: 0 0.12rem 0.12rem 0;
}
.qrcode img {
  width: 100%;
}
@keyframes key-fade-in {
  0% {
    transform: translateY(-0.6rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@media screen and (max-width: 1000px) {
  .active-box-content img {
    width: 100%;
  }
  .active-box-content-copy {
    top: 1rem;
    height: 0.4rem;
    right: 0;
  }
  .active-box-content-close {
    right: 0.1rem;
  }
  .active-box-content-action {
    bottom: -0.2rem;
  }
  .active-box-content-action-item {
    width: 1rem;
    height: 0.6rem;
  }
}

.gpt3 .ant-input-search .ant-input-wrapper {
  border-bottom: 0.01rem solid #eee;
  transition: border-color 0.3s ease-out;
}
.gpt3 .ant-input-search .ant-input-wrapper:hover {
  border-color: #2C3333;
}
.gpt3 .ant-input-search input {
  border: none !important;
  border-radius: 0;
  padding-right: 0 !important;
  text-align: center;
}
.gpt3 .ant-input-search input:focus {
  box-shadow: none;
}
.gpt3 .ant-input-search .ant-input-prefix {
  left: 0;
}
.gpt3 .ant-input-search .ant-input-prefix img {
  width: 0.25rem;
}
.gpt3 .ant-input-search .ant-btn {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
  padding: 0;
  text-align: right;
  width: 0.5rem;
  opacity: 0.5;
}
.gpt3 .ant-input-search .ant-btn:hover {
  opacity: 1;
}
.gpt3 .ant-input-search .ant-btn img {
  width: 0.2rem;
}
.gpt3-condition {
  margin-top: 0.24rem;
  font-size: 0.12rem;
}
.gpt3-condition span {
  margin-right: 0.06rem;
  color: #aaa;
}
.gpt3-condition .ant-select {
  font-size: 0.12rem;
  width: 0.8rem;
}
.gpt3-condition .ant-select .ant-select-selection-selected-value {
  padding: 0;
}
.gpt3-condition .ant-select-arrow {
  right: 0;
  margin-right: 0;
}
.gpt3-condition .ant-select-selection {
  border: none;
  border-radius: 0;
  border-bottom: 0.01rem solid #eee;
}
.gpt3-condition-item {
  margin-right: 0.12rem;
  margin-bottom: 0.12rem;
}
.gpt3-condition-item.switch {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.02rem 0.03rem;
  border-radius: 0.15rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.02rem;
  transition: background-color 0.3s ease-out;
}
.gpt3-condition-item.switch span {
  color: #fff;
  padding-left: 0.05rem;
  transform: scale(0.9);
  font-weight: 700;
  margin-right: 0.03rem;
}
.gpt3-condition-item.active {
  background-color: rgba(21, 135, 139, 0.65);
}
.gpt3 h2 {
  display: flex;
  align-items: center;
}
.gpt3 h2 button {
  margin-right: 0.12rem;
}
.gpt3 h2 strong {
  margin: 0 0.06rem;
  color: #ff4848;
}
.gpt3 .answer-status {
  margin-top: 0.12rem;
  padding: 0.06rem 0.12rem;
  background-color: rgba(53, 55, 64, 0.95);
  border-radius: 0.03rem 0.03rem 0 0;
  font-size: 0.12rem;
  color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.3s ease-out;
  text-align: center;
}
.gpt3 .answer-status a {
  color: #fff;
  text-decoration: underline;
  text-underline-offset: 0.03rem;
  margin-left: 0.03rem;
}
.gpt3 .answer-status.loading {
  background: linear-gradient(132deg, rgba(53, 55, 64, 0.95), rgba(53, 55, 64, 0.95), rgba(53, 55, 64, 0.7), rgba(53, 55, 64, 0.8), rgba(53, 55, 64, 0.95));
  background-size: 400% 400%;
  animation: Gradient 10s ease infinite;
}
.gpt3 .answer {
  min-height: 3rem;
  width: 100%;
  background-color: #353740;
  border-radius: 0 0 0.03rem 0.03rem;
  padding: 0.24rem 0.24rem 0.48rem 0.24rem;
  position: relative;
  margin-bottom: 0.12rem;
}
.gpt3 .answer pre {
  font-size: 0.16rem;
  color: #fff;
  white-space: pre-wrap;
  line-height: 0.32rem;
  -webkit-user-select: none;
          user-select: none;
  margin-bottom: 0;
}
.gpt3 .answer pre.cursor:after {
  content: "|";
  opacity: 0;
  font-weight: 700;
  color: #FFF;
  animation: cursor 1s infinite;
}
.gpt3 .answer-count {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.03rem 0.12rem;
  padding-left: 0.08rem;
  padding-right: 0.06rem;
  border-radius: 0.06rem 0 0 0;
  justsify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.gpt3 .answer-count span {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.1rem;
}
.gpt3 .answer-count span strong {
  margin-right: 0.02rem;
  font-size: 0.12rem;
  color: rgba(255, 255, 255, 0.7);
}
.gpt3 .answer-count-icon {
  width: 0.16rem;
  margin-right: 0.06rem;
  position: relative;
  top: -0.01rem;
  opacity: 0.7;
}
.gpt3 .answer .mp-guide {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.03rem 0.06rem;
  border-radius: 0 0.06rem 0 0;
  background-color: rgba(255, 255, 255, 0.1);
}
.gpt3 .answer .mp-guide img {
  width: 0.2rem;
  opacity: 0.5;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
}
.gpt3 .answer .mp-guide img:hover {
  opacity: 1;
}
.gpt3-btn-group {
  margin: 0 0 0.24rem 0;
}
@keyframes cursor {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes breath {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gpt3-prompt {
  position: relative;
  border-bottom: 0.01rem solid #eee;
  transition: border-color 0.3s ease-out;
  padding-bottom: 0.3rem;
}
.gpt3-prompt.disabled {
  background-color: #f5f5f5;
}
.gpt3-prompt.disabled .gpt3-prompt-send {
  opacity: 0.2 !important;
  cursor: not-allowed;
}
.gpt3-prompt-logo {
  width: 0.2rem;
  position: absolute;
  left: 0.04rem;
  bottom: 0;
  z-index: 9;
  padding: 0.06rem 0;
}
.gpt3-prompt textarea {
  padding: 0.12rem 0.3rem;
  border: none;
  border-radius: 0;
  text-align: center;
}
.gpt3-prompt textarea:focus {
  box-shadow: none;
}
.gpt3-prompt:hover {
  border-color: #2C3333;
}
.gpt3-prompt-send {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
  padding: 0.06rem 0.04rem 0.06rem 0.24rem;
}
.gpt3-prompt-send:hover {
  opacity: 1;
}
.gpt3-prompt-send img {
  width: 0.2rem;
}
.gpt3 .ant-input-search .ant-input-wrapper {
  border-bottom: 0.01rem solid #eee;
  transition: border-color 0.3s ease-out;
}
.gpt3 .ant-input-search .ant-input-wrapper:hover {
  border-color: #2C3333;
}
.gpt3 .ant-input-search input {
  border: none !important;
  border-radius: 0;
  padding-right: 0 !important;
  text-align: center;
}
.gpt3 .ant-input-search input:focus {
  box-shadow: none;
}
.gpt3 .ant-input-search .ant-input-prefix {
  left: 0;
}
.gpt3 .ant-input-search .ant-input-prefix img {
  width: 0.25rem;
}
.gpt3 .ant-input-search .ant-btn {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
  padding: 0;
  text-align: right;
  width: 0.5rem;
  opacity: 0.5;
}
.gpt3 .ant-input-search .ant-btn:hover {
  opacity: 1;
}
.gpt3 .ant-input-search .ant-btn img {
  width: 0.2rem;
}
.gpt3-condition {
  margin-top: 0.24rem;
  font-size: 0.12rem;
}
.gpt3-condition span {
  margin-right: 0.06rem;
  color: #aaa;
}
.gpt3-condition .ant-select {
  font-size: 0.12rem;
  width: 0.8rem;
}
.gpt3-condition .ant-select .ant-select-selection-selected-value {
  padding: 0;
}
.gpt3-condition .ant-select-arrow {
  right: 0;
  margin-right: 0;
}
.gpt3-condition .ant-select-selection {
  border: none;
  border-radius: 0;
  border-bottom: 0.01rem solid #eee;
}
.gpt3-condition-item {
  margin-right: 0.12rem;
  margin-bottom: 0.12rem;
}
.gpt3-condition-item.switch {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.02rem 0.03rem;
  border-radius: 0.15rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.02rem;
  transition: background-color 0.3s ease-out;
}
.gpt3-condition-item.switch span {
  color: #fff;
  padding-left: 0.05rem;
  transform: scale(0.9);
  font-weight: 700;
  margin-right: 0.03rem;
}
.gpt3-condition-item.active {
  background-color: rgba(21, 135, 139, 0.65);
}
.gpt3 h2 {
  display: flex;
  align-items: center;
}
.gpt3 h2 button {
  margin-right: 0.12rem;
}
.gpt3 h2 strong {
  margin: 0 0.06rem;
  color: #ff4848;
}
.gpt3 .answer-status {
  margin-top: 0.12rem;
  padding: 0.06rem 0.12rem;
  background-color: rgba(53, 55, 64, 0.95);
  border-radius: 0.03rem 0.03rem 0 0;
  font-size: 0.12rem;
  color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.3s ease-out;
  text-align: center;
}
.gpt3 .answer-status a {
  color: #fff;
  text-decoration: underline;
  text-underline-offset: 0.03rem;
  margin-left: 0.03rem;
}
.gpt3 .answer-status.loading {
  background: linear-gradient(132deg, rgba(53, 55, 64, 0.95), rgba(53, 55, 64, 0.95), rgba(53, 55, 64, 0.7), rgba(53, 55, 64, 0.8), rgba(53, 55, 64, 0.95));
  background-size: 400% 400%;
  animation: Gradient 10s ease infinite;
}
.gpt3 .answer {
  min-height: 3rem;
  width: 100%;
  background-color: #353740;
  border-radius: 0 0 0.03rem 0.03rem;
  padding: 0.24rem 0.24rem 0.48rem 0.24rem;
  position: relative;
  margin-bottom: 0.12rem;
}
.gpt3 .answer pre {
  font-size: 0.16rem;
  color: #fff;
  white-space: pre-wrap;
  line-height: 0.32rem;
  -webkit-user-select: none;
          user-select: none;
  margin-bottom: 0;
}
.gpt3 .answer pre.cursor:after {
  content: "|";
  opacity: 0;
  font-weight: 700;
  color: #FFF;
  animation: cursor 1s infinite;
}
.gpt3 .answer-count {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.03rem 0.12rem;
  padding-left: 0.08rem;
  padding-right: 0.06rem;
  border-radius: 0.06rem 0 0 0;
  justsify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.gpt3 .answer-count span {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.12rem;
}
.gpt3 .answer-count span strong {
  color: rgba(255, 255, 255, 0.7);
}
.gpt3 .answer-count-icon {
  width: 0.16rem;
  margin-right: 0.06rem;
  position: relative;
  top: -0.01rem;
  opacity: 0.7;
}
.gpt3 .answer .mp-guide {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.03rem 0.06rem;
  border-radius: 0 0.06rem 0 0;
  background-color: rgba(255, 255, 255, 0.1);
}
.gpt3 .answer .mp-guide img {
  width: 0.2rem;
  opacity: 0.5;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
}
.gpt3 .answer .mp-guide img:hover {
  opacity: 1;
}
.gpt3-btn-group {
  margin: 0 0 0.24rem 0;
}
@keyframes cursor {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes breath {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gpt3-prompt {
  position: relative;
  border-bottom: 0.01rem solid #eee;
  transition: border-color 0.3s ease-out;
  padding-bottom: 0.3rem;
}
.gpt3-prompt.disabled {
  background-color: #f5f5f5;
}
.gpt3-prompt.disabled .gpt3-prompt-send {
  opacity: 0.2 !important;
  cursor: not-allowed;
}
.gpt3-prompt-logo {
  width: 0.2rem;
  position: absolute;
  left: 0.04rem;
  bottom: 0;
  z-index: 9;
  padding: 0.06rem 0;
}
.gpt3-prompt textarea {
  padding: 0.12rem 0.3rem;
  border: none;
  border-radius: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gpt3-prompt textarea:focus {
  box-shadow: none;
}
.gpt3-prompt:hover {
  border-color: #2C3333;
}
.gpt3-prompt-send {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
  padding: 0.06rem 0.04rem 0.06rem 0.24rem;
}
.gpt3-prompt-send:hover {
  opacity: 1;
}
.gpt3-prompt-send img {
  width: 0.2rem;
}
.gpt3 .ant-input-search .ant-input-wrapper {
  border-bottom: 0.01rem solid #eee;
  transition: border-color 0.3s ease-out;
}
.gpt3 .ant-input-search .ant-input-wrapper:hover {
  border-color: #2C3333;
}
.gpt3 .ant-input-search input {
  border: none !important;
  border-radius: 0;
  padding-right: 0 !important;
  text-align: center;
}
.gpt3 .ant-input-search input:focus {
  box-shadow: none;
}
.gpt3 .ant-input-search .ant-input-prefix {
  left: 0;
}
.gpt3 .ant-input-search .ant-input-prefix img {
  width: 0.25rem;
}
.gpt3 .ant-input-search .ant-btn {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
  padding: 0;
  text-align: right;
  width: 0.5rem;
  opacity: 0.5;
}
.gpt3 .ant-input-search .ant-btn:hover {
  opacity: 1;
}
.gpt3 .ant-input-search .ant-btn img {
  width: 0.2rem;
}
.gpt3-condition {
  margin-top: 0.24rem;
  font-size: 0.12rem;
}
.gpt3-condition span {
  margin-right: 0.06rem;
  color: #aaa;
}
.gpt3-condition .ant-select {
  font-size: 0.12rem;
  width: 0.8rem;
}
.gpt3-condition .ant-select .ant-select-selection-selected-value {
  padding: 0;
}
.gpt3-condition .ant-select-arrow {
  right: 0;
  margin-right: 0;
}
.gpt3-condition .ant-select-selection {
  border: none;
  border-radius: 0;
  border-bottom: 0.01rem solid #eee;
}
.gpt3-condition-item {
  margin-right: 0.12rem;
  margin-bottom: 0.12rem;
}
.gpt3-condition-item.switch {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.02rem 0.03rem;
  border-radius: 0.15rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.02rem;
  transition: background-color 0.3s ease-out;
}
.gpt3-condition-item.switch span {
  color: #fff;
  padding-left: 0.05rem;
  transform: scale(0.9);
  font-weight: 700;
  margin-right: 0.03rem;
}
.gpt3-condition-item.active {
  background-color: rgba(21, 135, 139, 0.65);
}
.gpt3 h2 {
  display: flex;
  align-items: center;
}
.gpt3 h2 button {
  margin-right: 0.12rem;
}
.gpt3 h2 strong {
  margin: 0 0.06rem;
  color: #ff4848;
}
.gpt3 .answer-status {
  margin-top: 0.12rem;
  padding: 0.06rem 0.12rem;
  background-color: rgba(53, 55, 64, 0.95);
  border-radius: 0.03rem 0.03rem 0 0;
  font-size: 0.12rem;
  color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.3s ease-out;
  text-align: center;
}
.gpt3 .answer-status a {
  color: #fff;
  text-decoration: underline;
  text-underline-offset: 0.03rem;
  margin-left: 0.03rem;
}
.gpt3 .answer-status.loading {
  background: linear-gradient(132deg, rgba(53, 55, 64, 0.95), rgba(53, 55, 64, 0.95), rgba(53, 55, 64, 0.7), rgba(53, 55, 64, 0.8), rgba(53, 55, 64, 0.95));
  background-size: 400% 400%;
  animation: Gradient 10s ease infinite;
}
.gpt3 .answer {
  min-height: 3rem;
  width: 100%;
  background-color: #353740;
  border-radius: 0 0 0.03rem 0.03rem;
  padding: 0.24rem 0.24rem 0.48rem 0.24rem;
  position: relative;
  margin-bottom: 0.12rem;
}
.gpt3 .answer pre {
  font-size: 0.16rem;
  color: #fff;
  white-space: pre-wrap;
  line-height: 0.32rem;
  -webkit-user-select: none;
          user-select: none;
  margin-bottom: 0;
}
.gpt3 .answer pre.cursor:after {
  content: "|";
  opacity: 0;
  font-weight: 700;
  color: #FFF;
  animation: cursor 1s infinite;
}
.gpt3 .answer-count {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.03rem 0.12rem;
  padding-left: 0.08rem;
  padding-right: 0.06rem;
  border-radius: 0.06rem 0 0 0;
  justsify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.gpt3 .answer-count span {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.12rem;
}
.gpt3 .answer-count span strong {
  color: rgba(255, 255, 255, 0.7);
}
.gpt3 .answer-count-icon {
  width: 0.16rem;
  margin-right: 0.06rem;
  position: relative;
  top: -0.01rem;
  opacity: 0.7;
}
.gpt3 .answer .mp-guide {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.03rem 0.06rem;
  border-radius: 0 0.06rem 0 0;
  background-color: rgba(255, 255, 255, 0.1);
}
.gpt3 .answer .mp-guide img {
  width: 0.2rem;
  opacity: 0.5;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
}
.gpt3 .answer .mp-guide img:hover {
  opacity: 1;
}
.gpt3-btn-group {
  margin: 0 0 0.24rem 0;
}
@keyframes cursor {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes breath {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.ai-score {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.02rem 0.12rem;
  background-color: #CBE4DE;
  border: 0.01rem solid #a9d3c9;
  border-radius: 0.03rem;
  cursor: pointer;
  color: #0E8388;
  font-size: 0.14rem;
  font-weight: 700;
}
.ai-score-count {
  display: flex;
  align-items: center;
}
.ai-score-count-icon {
  width: 0.14rem;
  margin-right: 0.06rem;
}
.ai-tab-title {
  display: flex;
  align-items: center;
}
.ai-tab-title img {
  width: 0.22rem;
  margin-right: 0.03rem;
  border: 0.01rem solid;
  border-radius: 100%;
}
.ai .ant-tabs.wa .ant-tabs-tab.ant-tabs-tab-active {
  color: #bf63ea !important;
}
.ai .ant-tabs.wa .ant-tabs-ink-bar {
  background-color: #bf63ea !important;
}
.ai .ant-tabs.xhs .ant-tabs-tab.ant-tabs-tab-active {
  color: #ff2741 !important;
}
.ai .ant-tabs.xhs .ant-tabs-ink-bar {
  background-color: #ff2741 !important;
}
.ai .ant-tabs.zc .ant-tabs-tab.ant-tabs-tab-active {
  color: #f78341 !important;
}
.ai .ant-tabs.zc .ant-tabs-ink-bar {
  background-color: #f78341 !important;
}
.ai .ant-tabs.cx .ant-tabs-tab.ant-tabs-tab-active {
  color: #1eb4bb !important;
}
.ai .ant-tabs.cx .ant-tabs-ink-bar {
  background-color: #1eb4bb !important;
}
.ai .ant-tabs.dh .ant-tabs-tab.ant-tabs-tab-active {
  color: #20d76d !important;
}
.ai .ant-tabs.dh .ant-tabs-ink-bar {
  background-color: #20d76d !important;
}
.ant-tabs-bar {
  border-bottom: none !important;
}
.ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-tabs-nav-scroll .ant-tabs-tab {
  color: #bbb;
  transition: all 0.3s ease;
  font-size: 0.12rem;
  padding: 0.06rem 0.16rem !important;
}
.ant-tabs-nav-scroll .ant-tabs-tab.ant-tabs-tab-active {
  font-weight: 700;
}
.ant-tabs-nav-scroll .ant-tabs-tab:hover {
  color: #888;
}
.ant-tabs-nav-scroll .ant-tabs-ink-bar {
  width: 0.06rem !important;
  height: 0.06rem;
  position: relative;
  border-radius: 0.05rem;
  left: 0.24rem;
  background-color: #2C3333;
}
@media screen and (max-width: 1000px) {
  .ant-tabs-nav-scroll {
    display: block;
  }
}
@keyframes cursor {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes breath {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.tools textarea {
  margin-bottom: 0.12rem;
}
.tools input {
  margin: 0.12rem 0 0.24rem 0;
}

.lunar-list-content-month {
  margin-bottom: 0.5rem;
}
.lunar-list-content-month-title {
  text-align: left;
  font-size: 0.14rem;
  font-weight: 700;
  color: #bbb;
  text-shadow: 0.01rem 0.01rem 0.03rem rgba(0, 0, 0, 0.1);
  width: 100%;
  border-bottom: 0.01rem solid #f5f5f6;
  padding: 0.12rem 0;
  margin-bottom: 0.12rem;
  position: relative;
}
.lunar-list-content-month-title::before {
  height: 0.12rem;
  margin-right: 0.06rem;
  display: inline-block;
  width: 0.04rem;
  border-radius: 0.06rem;
  background-color: #bbb;
  position: relative;
  top: 0.01rem;
  content: ' ';
}
.lunar-list-content-month-day {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.lunar-list-content-month-day-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-out;
  padding: 0.06rem 0.32rem;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.03);
  margin: 0.06rem 0;
  margin-right: 0.12rem;
  border-radius: 0.06rem;
}
.lunar-list-content-month-day-item:hover {
  box-shadow: 0 0 0.05rem 0.03rem rgba(0, 0, 0, 0.1);
}
.lunar-list-content-month-day-item.expired .lunar-list-content-month-day-item-name,
.lunar-list-content-month-day-item.expired .lunar-list-content-month-day-item-date {
  color: #ccc;
  font-weight: 300;
}
.lunar-list-content-month-day-item img {
  width: 0.3rem;
  height: 0.3rem;
  position: absolute;
  right: -0.05rem;
  bottom: -0.06rem;
  opacity: 0.3;
  transform: rotate(-20deg);
}
.lunar-list-content-month-day-item .hot {
  width: 0.28rem;
  height: 0.28rem;
  right: 0;
  top: 0;
  transform: rotate(0deg);
  opacity: 1;
}
.lunar-list-content-month-day-item-name {
  font-size: 0.16rem;
  font-weight: 700;
  color: #233333;
}
.lunar-list-content-month-day-item-date {
  font-size: 0.1rem;
  font-weight: 300;
  color: #bbb;
  text-align: center;
}
.lunar-list-year {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}
.lunar-list-year .ant-radio-group .ant-radio-button-wrapper {
  font-size: 0.12rem;
  border-color: #f5f5f6;
  color: #999;
}
.lunar-list-year .ant-radio-group .ant-radio-button-wrapper:hover {
  color: #233333;
}
.lunar-list-year .ant-radio-group .ant-radio-button-wrapper:focus {
  box-shadow: none;
  outline: none;
}
.lunar-list-year .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: #233333;
  background-color: #fff;
  color: #233333;
  box-shadow: -0.01rem 0 0 0 #233333;
}
.lunar-list-year .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked::before {
  display: none;
}

@font-face {
  font-family: "iconfont";
  /* Project id 4651509 */
  src: url(/static/media/iconfont.dce44c2e.woff2) format('woff2'), url(/static/media/iconfont.1311db07.woff) format('woff'), url(/static/media/iconfont.6880eae7.ttf) format('truetype');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 0.2rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-Settings:before {
  content: "\E641";
}
.icon-shoucang2:before {
  content: "\E607";
}
.icon-jingxuanjiacu:before {
  content: "\E616";
}
.icon-shoucang1:before {
  content: "\E622";
}
.icon-swapright_line:before {
  content: "\E60D";
}
.icon-fire:before {
  content: "\E729";
}
.icon-qingchu:before {
  content: "\E763";
}
.icon-xiazai-L:before {
  content: "\E61E";
}
.icon-xuanzhuan:before {
  content: "\E619";
}
.icon-xuanzhuan1:before {
  content: "\E662";
}
.icon-icon_tupianxuanzhuan:before {
  content: "\E601";
}
.icon-tianjiawenzi:before {
  content: "\E744";
}
.icon-tianjiatupian_huaban:before {
  content: "\E62C";
}
.icon-tupian:before {
  content: "\E63C";
}
.icon-wenzi_huaban1:before {
  content: "\E67F";
}
.icon-xieti:before {
  content: "\E7AD";
}
.icon-shanchu:before {
  content: "\E600";
}
.icon-icon_c_zitiyinying:before {
  content: "\E61B";
}
.icon-zitiyanse:before {
  content: "\E61F";
}
.icon-zitibeijing:before {
  content: "\E632";
}
.icon-cuxi-4:before {
  content: "\E6EF";
}
.icon-cuxi:before {
  content: "\E61D";
}

.meme-item {
  border-radius: 0.06rem;
  width: 100%;
  height: 1.3rem;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border: 0.01rem solid #f5f5f6;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  transition: box-shadow 0.3s ease-in;
}
.meme-item:hover {
  box-shadow: 0 0 0.05rem 0.05rem rgba(0, 0, 0, 0.05);
}
.meme-item-img {
  width: 1.3rem;
  height: 1.3rem;
  background-color: #f5f5f6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0.01rem solid #f5f5f6;
  cursor: pointer;
}
.meme-item-img img {
  max-width: 100%;
  /* 图片最大宽度不超过容器宽度 */
  max-height: 100%;
  /* 图片最大高度不超过容器高度 */
  height: auto;
  /* 根据宽高比自动调整高度 */
  width: auto;
  /* 根据宽高比自动调整宽度 */
  pointer-events: none;
}
.meme-item-img-hot {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 0.12rem;
  padding: 0.01rem 0.03rem 0.01rem 0.04rem;
  border-radius: 0 0 0.06rem 0;
}
.meme-item-img-hot span {
  font-size: 0.12rem;
  margin-right: 0.03rem;
  color: rgba(255, 255, 255, 0.8);
}
.meme-item-info {
  width: calc(100% - 1.3rem);
  height: 100%;
  position: relative;
}
.meme-item-info-name {
  font-size: 0.13rem;
  margin: 0.12rem 0.12rem 0.06rem 0.12rem;
  text-shadow: 0.01rem 0.01rem 0.05rem rgba(0, 0, 0, 0.2);
  color: #292828;
  font-weight: 700;
  cursor: pointer;
  width: calc(100% - 0.3rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.meme-item-info-tags {
  margin: 0 0.12rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 0.4rem;
  overflow-y: auto;
}
.meme-item-info-tags span {
  font-size: 0.12rem;
  color: #c8c8c8;
  margin-right: 0.06rem;
  display: inline-block;
  height: 0.12rem;
  white-space: nowrap;
  cursor: pointer;
}
.meme-item-info-tags span:hover {
  color: #34a8f1;
  text-decoration: underline;
  text-underline-offset: 0.04rem;
}
.meme-item-info-action {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  height: 0.36rem;
  border-top: 0.01rem solid #f5f5f6;
  width: 100%;
}
.meme-item-info-action-item {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #888;
}
.meme-item-info-action-item.ed {
  color: #34a8f1;
}
.meme-item-info-action-item:first-child {
  border-right: 0.01rem solid #f5f5f6;
}
.meme-item-info-action-item:hover {
  background-color: #34a8f1;
  color: #fff;
}
.meme-item-info-action-item.love .iconfont {
  margin-right: 0.06rem;
}
.meme-item-info-action-item.love.ed .iconfont {
  margin-right: 0;
}
.meme-item-info-action-item.create .iconfont {
  margin-left: 0.06rem;
}

.meme {
  width: 100%;
  height: 100%;
  margin-bottom: 0.2rem;
}
.meme .ant-radio-group .ant-radio-button-wrapper {
  font-size: 0.12rem;
  border-color: #f5f5f6;
  color: #999;
}
.meme .ant-radio-group .ant-radio-button-wrapper:hover {
  color: #454545;
}
.meme .ant-radio-group .ant-radio-button-wrapper:focus {
  box-shadow: none;
  outline: none;
}
.meme .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: #454545;
  background-color: #454545;
  color: #fff;
  box-shadow: -0.01rem 0 0 0 #454545;
}
.meme .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked::before {
  display: none;
}
.meme-search {
  display: none;
}
.meme-tools {
  margin-bottom: 0.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meme-tools-free {
  margin-left: 0.12rem;
}
.meme-action {
  display: flex;
  justify-content: center;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  align-items: center;
  margin: 0.16rem 0;
  height: 0.32rem;
}
.meme-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  width: 100%;
  position: relative;
  height: 4.2rem;
  overflow: auto;
}
.meme-list-loading {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 0.16rem;
  color: #333;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.8rem 0;
}
.meme-list-item {
  width: 49%;
}
.meme-list-item-skeleton-pic {
  width: 1.3rem;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.06);
}
.meme-list-item.skeleton {
  background-color: rgba(255, 255, 255, 0.6);
  height: 1.3rem;
  border-radius: 0.06rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meme-list-item.skeleton .ant-skeleton {
  padding: 0 0.12rem;
  width: calc(100% - 1.3rem);
}
.meme-list-item.skeleton .ant-skeleton-title {
  margin-top: 0;
}
@media screen and (max-width: 1000px) {
  .meme-search {
    display: block;
  }
  .meme-tools {
    margin-top: 0.12rem;
  }
  .meme-tools .ant-input-search {
    display: none;
  }
  .meme-list-item {
    width: 100%;
  }
}

.privacy-policy {
  width: 100%;
  height: 100%;
  padding: 0.24rem;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  line-height: 0.28rem;
}
.privacy-policy h2 {
  margin-top: 0.3rem;
}

.memes-content {
  background-color: rgba(0, 0, 0, 0.01);
  padding: 0.12rem 0.5rem 0.12rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.12rem;
  min-height: 4rem;
}
.memes-content-pic {
  width: 100%;
  height: 5rem;
}
.memes-content-water {
  font-size: 0.16rem;
  position: absolute;
  font-weight: 700;
  text-shadow: 0.01rem 0.01rem 0.05rem #333;
  color: #fff;
  opacity: 0.3;
}
.memes-name {
  margin: 0.12rem 0;
  min-height: 0.4rem;
  text-align: center;
  display: none;
}
.memes-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}
.memes-action button {
  margin: 0 0.12rem;
  width: 20%;
}

.meme-creator-item-text {
  width: 100%;
}
.meme-creator-item-text .ant-input-affix-wrapper {
  border-color: #000;
  box-shadow: none;
}
.meme-creator-item-text textarea {
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  color: #000;
}
.meme-creator-item-text textarea:focus {
  outline: none;
  border-color: #000;
}
.meme-creator-item-text textarea:hover {
  border-color: #000;
}
.meme-creator-item-text-action {
  margin: 0.03rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}
.meme-creator-item-text-action-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.12rem;
}
.meme-creator-item-text-action-item span {
  margin-right: 0.03rem;
  font-size: 0.18rem;
  transition: opacity 0.3s ease-in;
}
.meme-creator-item-text-action-item span.icon {
  cursor: pointer;
  margin-right: 0;
  opacity: 1;
}
.meme-creator-item-text-action-item span.disable {
  opacity: 0.3;
}
.meme-creator-item-text-action-item span.enable {
  opacity: 1;
}
.meme-creator-item-text-action-item:not(.icon):hover span {
  opacity: 1;
}
.meme-creator-item-text-action-item.del {
  transition: opacity 0.3s ease-in;
  opacity: 0.3;
  font-size: 0.18rem;
  position: absolute;
  margin-right: 0;
  right: 0;
  cursor: pointer;
}
.meme-creator-item-text-action-item.del:hover {
  opacity: 1;
}
.meme-creator-item-text-action-item.del span {
  margin-right: 0;
}
.meme-creator-item-text-action-item input {
  cursor: pointer;
}
.meme-creator-item-text-action-item-slider {
  width: 0.4rem;
}
.meme-creator-item-text-action-item .ant-slider {
  margin: 0 !important;
}
.meme-creator-item-text-action-item .ant-slider .ant-slider-track {
  background-color: #000;
}
.meme-creator-item-text-action-item .ant-slider .ant-slider-handle {
  border-color: #000;
  width: 0.12rem;
  height: 0.12rem;
  margin-top: -0.04rem;
}
.meme-creator-item-text-action-item .ant-slider .ant-slider-handle:focus {
  box-shadow: 0 0 0.05rem rgba(0, 0, 0, 0.2);
}
.meme-creator-item-text-action-item .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #000;
}

.meme-creator-item-image {
  width: 1rem;
}
.meme-creator-item-image-content {
  width: 100%;
  height: 1rem;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* 设置容器为相对定位 */
  box-shadow: 0 0 0.08rem 0.01rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.meme-creator-item-image-content img {
  max-width: 100%;
  /* 图片最大宽度不超过容器宽度 */
  max-height: 100%;
  /* 图片最大高度不超过容器高度 */
  height: auto;
  /* 根据宽高比自动调整高度 */
  width: auto;
  /* 根据宽高比自动调整宽度 */
}
.meme-creator-item-image-action {
  margin: 0.03rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}
.meme-creator-item-image-action-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.05rem;
  cursor: pointer;
}
.meme-creator-item-image-action-item span {
  font-size: 0.22rem;
  transition: opacity 0.3s ease-in;
  opacity: 0.5;
}
.meme-creator-item-image-action-item span.icon {
  cursor: pointer;
  margin-right: 0;
}
.meme-creator-item-image-action-item span.icon.vertical {
  transform: rotate(-90deg);
}
.meme-creator-item-image-action-item:hover span {
  opacity: 1;
}
.meme-creator-item-image-action-item.del {
  transition: opacity 0.3s ease-in;
  opacity: 0.3;
  font-size: 0.18rem;
  position: absolute;
  margin-right: 0;
  right: 0;
}
.meme-creator-item-image-action-item.del:hover {
  opacity: 1;
}
.meme-creator-item-image-action-item.del span {
  margin-right: 0;
}
.meme-creator-item-image-action-item input {
  cursor: pointer;
}


.meme-pop-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.08rem 0;
}
.meme-pop-action-create {
  display: flex;
  align-items: center;
}
.meme-pop-action-create i {
  font-size: 0.18rem;
  margin-left: 0.04rem !important;
}

.meme-creator {
  width: calc(100% - 0.24rem);
  height: 100%;
  padding: 0.12rem;
  margin: 0 0.12rem;
  margin-bottom: 0.12rem;
  border-radius: 0.06rem;
  box-sizing: border-box;
  background-color: #fff;
  overflow: auto;
}
.meme-creator-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.meme-creator-content-preview {
  box-shadow: 0 0 0.08rem 0.01rem rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.meme-creator-content-tools {
  width: calc(100% - 4rem);
  padding: 0 0.12rem;
  padding-right: 0;
  position: relative;
}
.meme-creator-content-tools-action {
  height: 0.32rem;
  margin-bottom: 0.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meme-creator-content-tools-container {
  overflow: auto;
  margin-bottom: 0.12rem;
}
.meme-creator-content-tools-container-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  overflow-x: auto;
  padding: 0.12rem 0.05rem 0 0.05rem;
}
.meme-creator-content-tools-container-item {
  margin-bottom: 0.12rem;
}
.meme-creator-content-tools-container-empty {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.16rem;
  color: #ccc;
  padding: 0.2rem 0;
}
.meme-creator-content-tools-footer {
  height: 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meme-creator-content-tools-footer-item {
  margin-right: 0.12rem;
}
.meme-creator-content-tools-footer-sync {
  text-align: center;
  line-height: 0.15rem;
}
.meme-creator-content-tools-footer-sync-text {
  text-shadow: 0 0 0.03rem rgba(0, 0, 0, 0.1);
  color: #999;
}
.meme-creator-content-tools-footer-sync span {
  font-size: 0.11rem;
}
.meme-creator-content-tools-footer-sync .ant-switch-checked {
  background-color: #333;
}
.meme-creator-content-tools .ant-btn-group {
  display: flex;
  justify-content: flex-start;
}
.meme-creator-content-tools .ant-btn-group button {
  margin: 0;
  padding: 0 0.08rem 0 0.06rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  border-color: #000;
  color: #000;
}
.meme-creator-content-tools .ant-btn-group button:hover {
  opacity: 1;
  background-color: #333;
  color: #fff;
}
.meme-creator-content-tools .ant-btn-group button:first-child {
  border-right-color: transparent;
}
.meme-creator-content-tools .ant-btn-group button:first-child:hover {
  border-right-color: #000;
}
.meme-creator-content-tools .ant-btn-group button:nth-child(3) {
  border-left-color: transparent;
}
.meme-creator-content-tools .ant-btn-group button:nth-child(3):hover {
  border-left-color: #000;
}
.meme-creator-content-tools .ant-btn-group button i {
  font-size: 0.24rem;
  position: relative;
  top: 0.01rem;
}
.meme-creator-content-tools .ant-btn-group button span {
  margin-left: 0.03rem;
}
input[type="color"] {
  -webkit-appearance: none;
  appearance: none;
  border: 0.01rem solid #ccc;
  width: 0.2rem;
  height: 0.15rem;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
input[type="file"] {
  display: none;
}

.meme-share-tips {
  border-radius: 0.3rem;
  font-size: 0.12rem;
  overflow: hidden;
  box-shadow: 0 0 0.08rem 0.01rem rgba(0, 0, 0, 0.1);
  font-family: bailubangbangshouxieti;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  animation-name: share-out;
  animation-duration: 0.3s;
  animation-delay: 3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.meme-share-tips span {
  padding: 0.06rem 0.12rem;
  padding-right: 0;
}
.meme-share-tips strong {
  font-family: initial;
}
.meme-share-tips.hide {
  display: none;
}
@keyframes share-out {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.meme-share-tips-copy {
  padding: 0.06rem 0.12rem;
  cursor: pointer;
  color: #1890ff;
  background-color: #fff;
  font-family: initial;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}
.meme-share-tips-copy:hover {
  background-color: #40a9ff;
  color: #fff;
}

.meme-new {
  width: 10rem;
  min-height: 5.2rem;
  overflow: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-radius: 0.03rem;
}
.meme-new-info {
  padding: 0.12rem;
}
.meme-new-info-name {
  font-size: 0.2rem;
  font-weight: 700;
  margin-bottom: 0.12rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.meme-new-info-name-hot {
  font-size: 0.14rem;
  margin-left: 0.12rem;
  color: #ff4848;
}
.meme-new-info-name-hot span {
  font-size: 0.12rem;
  margin-left: 0.03rem;
}
.meme-new-info-tags .ant-tag {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.meme-new-info-tags .ant-tag:hover {
  background-color: #333;
  color: #fff;
  box-shadow: 0 0 0.04rem 0 rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 1000px) {
  .meme-new {
    width: 100% !important;
  }
  .meme-new-info-name-hot {
    text-align: center;
  }
  .meme-new-info-name-hot span {
    margin-left: 0;
  }
}

.meme-new {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.meme-new-info {
  padding: 0.12rem;
}
.meme-new-info-name {
  font-size: 0.2rem;
  font-weight: 700;
  margin-bottom: 0.12rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.meme-new-info-name button {
  margin-right: 0.06rem;
}
.meme-new-info-name-hot {
  font-size: 0.14rem;
  margin-left: 0.12rem;
  color: #ff4848;
}
.meme-new-info-name-hot span {
  font-size: 0.12rem;
  margin-left: 0.03rem;
}

.user-menu-content-local-nickname,
.user-menu-content-online-nickname {
  padding-bottom: 0.12rem;
  margin-bottom: 0.15rem;
  border-bottom: 0.01rem solid #e5e5e6;
  text-align: center;
  width: 100%;
  font-size: 0.14rem;
  color: #566a6b;
  font-weight: 300;
  text-shadow: 0.01rem 0.01rem 0.03rem rgba(0, 0, 0, 0.2);
}
.user-menu-content-local-action,
.user-menu-content-online-action {
  width: 100%;
  margin-bottom: 0.12rem;
}
.user-menu-content-local-action button,
.user-menu-content-online-action button {
  width: 100%;
}
.user-menu-content-local-nickname-content {
  position: relative;
}
.user-menu-content-local-nickname-content-roll {
  position: absolute;
  right: -0.2rem;
  top: 0;
}
.user-menu-content-local-tips {
  font-size: 0.12rem;
  color: #888;
  margin-bottom: 0.12rem;
}
.user-menu-content-local-tips i {
  color: orange;
}

.user-menu {
  width: 0.35rem;
  height: 0.35rem;
  border-radius: 100%;
  background-color: #000;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-menu span {
  color: #cccccc !important;
  font-size: 0.16rem;
}

.footer-content {
  width: 10rem;
  left: calc(50% - 5rem);
  position: relative;
  padding-bottom: 1rem;
}
.footer-content-head {
  color: #fff;
  font-size: 0.2rem;
  text-align: center;
  padding: 0.06rem 0;
  margin-bottom: 0.24rem;
  border-bottom: 0.01rem solid rgba(255, 255, 255, 0.1);
}
.footer-content-body a,
.footer-content-body span {
  color: #fff;
  font-weight: 300;
  font-family: Arial;
  margin-right: 0.18rem;
  margin-bottom: 0.16rem;
  display: inline-block;
}
.footer-content-body img {
  border-radius: 0.03rem;
  box-shadow: 0 0 0.03rem 0.03rem rgba(255, 255, 255, 0.2);
}
.footer-content-links {
  margin-top: 0.2rem;
  font-size: 0.1rem;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0.12rem;
  color: rgba(255, 255, 255, 0.5);
}
.footer-content-links a {
  color: rgba(255, 255, 255, 0.8);
  margin-right: 0.12rem;
}
.footer-content .mp-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-wrap: wrap;
  width: 100%;
  bottom: 0.36rem;
}
.footer-content .mp-contact-item {
  margin: 0 0.06rem;
}
.footer-content .mp-contact-item a {
  color: #ddd;
  font-weight: 300;
  font-family: Arial, verdana;
  font-size: 0.1rem;
}
.footer-content .mp-contact-item.email a {
  color: #FFF;
  font-weight: 700;
}

.gpt-sider {
  width: 100%;
  margin-bottom: 0.12rem;
  padding: 0.06rem;
  box-sizing: border-box;
  position: relative;
  background-color: #CBE4DE;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
}
.gpt-sider-title {
  font-size: 0.14rem;
  color: #2E4F4F;
  font-weight: 300;
  text-align: center;
  border-radius: 0.03rem;
  background-color: #a9d3c9;
  padding: 0.03rem;
  margin-bottom: 0.06rem;
}
.gpt-sider-target {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gpt-sider-target-item {
  width: 33%;
  cursor: pointer;
  padding: 0.06rem 0.03rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in;
  border-radius: 0.06rem;
}
.gpt-sider-target-item:hover {
  background-color: #badbd3;
}
.gpt-sider-target-item-icon {
  text-align: center;
  margin-bottom: 0.03rem;
}
.gpt-sider-target-item-icon img {
  width: 0.22rem;
}
.gpt-sider-target-item-name {
  font-size: 0.12rem;
  font-weight: 700;
  text-align: center;
}
.gpt-sider-target-item-tips {
  font-size: 0.12rem;
  color: #999;
  text-align: center;
  margin-top: 0.06rem;
}
.gpt-sider .gpt-sider-info {
  padding: 0.12rem 0;
  border-radius: 0.03rem 0.03rem 0 0;
  background-color: #a9d3c9;
}
.gpt-sider .gpt-sider-info-tips {
  text-align: center;
  font-size: 0.12rem;
  color: #0E8388;
  opacity: 0.7;
}
.gpt-sider .gpt-sider-info-intro {
  font-size: 0.12rem;
  color: #0E8388;
  margin-top: 0.06rem;
}
.gpt-sider .gpt-sider-info-title {
  font-size: 0.16rem;
  color: #2E4F4F;
  font-weight: 700;
  text-align: center;
}
.gpt-sider-btn .ant-btn {
  width: 100%;
  border-radius: 0 0 0.03rem 0.03rem;
}
.gpt-sider-history {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.06rem 0 0.12rem 0;
}
.gpt-sider-history span {
  background-color: #a9d3c9;
  color: #0E8388;
  font-size: 0.12rem;
  padding: 0.01rem 0.12rem;
  width: auto;
  text-align: center;
  border-radius: 0.2rem;
}
.gpt-sider-history span a {
  color: #0E8388;
  text-decoration: underline;
}

.article-sider {
  width: 100%;
  padding: 0.1rem 0.15rem;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
  margin-bottom: 0.12rem;
}
.article-sider-content {
  margin-top: 0.06rem;
  width: 100%;
  box-sizing: border-box;
}
.article-sider-item {
  width: 100%;
  margin-bottom: 0.12rem;
  overflow: hidden;
}
.article-sider-item:last-child {
  margin-bottom: 0;
}
.article-sider-item-title {
  display: block;
  font-size: 0.12rem;
  opacity: 0.6;
  -webkit-user-select: none;
          user-select: none;
  text-align: justify;
  color: #3F4E4F;
  transition: all 0.3s ease-out;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.article-sider-item-title:hover {
  opacity: 1;
  text-shadow: 0.01rem 0.01rem 0.05rem rgba(0, 0, 0, 0.1);
}
.article-sider-item-sub-title {
  font-size: 0.12rem;
  color: #bbb;
  display: flex;
  justify-content: space-between;
}
.article-sider .loading-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.article-sider-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.article-sider-bg img {
  height: 100%;
}

.quan-sider {
  width: 100%;
  padding: 0.1rem 0.15rem;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
  margin-bottom: 0.12rem;
}
.quan-sider-content {
  margin-top: 0.06rem;
  width: 100%;
  box-sizing: border-box;
}
.quan-sider-item {
  width: 100%;
  margin-bottom: 0.12rem;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.quan-sider-item:last-child {
  margin-bottom: 0;
}
.quan-sider-item-title {
  margin-left: 0.06rem;
  display: block;
  font-size: 0.12rem;
  opacity: 0.6;
  -webkit-user-select: none;
          user-select: none;
  text-align: justify;
  color: #3F4E4F;
  transition: all 0.3s ease-out;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.quan-sider-item-title:hover {
  opacity: 1;
  text-shadow: 0.01rem 0.01rem 0.05rem rgba(0, 0, 0, 0.1);
}
.quan-sider-item-sub-title {
  font-size: 0.12rem;
  color: #bbb;
  display: flex;
  justify-content: space-between;
}
.quan-sider .loading-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quan-sider-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.quan-sider-bg img {
  height: 100%;
}

.ad {
  width: 100%;
  border-radius: 0.03rem;
  box-sizing: border-box;
  overflow: hidden;
}
.ad .carousel-item {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ad .carousel-item a {
  height: 100%;
}
.ad .carousel-item img {
  width: 100%;
  height: 100%;
}
.ad .extension-content {
  margin-left: 0.12rem;
}
.ad .extension-content-title {
  font-size: 0.2rem;
  color: #fff;
}
.ad .extension-content-title span {
  font-size: 0.1rem;
  color: rgba(255, 255, 255, 0.5);
}
.ad .extension-content-tips {
  font-size: 0.14rem;
  color: rgba(255, 255, 255, 0.5);
}

.root-sider {
  width: 2.85rem;
  box-sizing: border-box;
  position: fixed;
  top: 0.85rem;
}
.root-sider .ad {
  margin-bottom: 0.08rem;
}

.root {
  width: 100%;
  height: 100%;
}
.root-body {
  position: relative;
  top: 0.7rem;
  width: 10rem;
  min-width: 10rem;
  margin: 0 auto;
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
  height: calc(100% - 0.9rem);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.root-body-content {
  width: 100%;
  z-index: 9;
}
.root-body-content.child-page {
  width: calc(100% - 2.97rem);
  box-sizing: border-box;
  position: relative;
  min-height: 8rem;
  padding: 0.12rem 0.12rem 0.24rem 0.12rem;
  border-radius: 0.03rem;
  box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.02);
  background-color: #fff;
  overflow: visible;
}
.root-body-content.child-page .child-page-title {
  font-weight: 300;
  color: #555;
  margin: 0 0 0.12rem 0;
  padding-bottom: 0.12rem;
  font-size: 0.16rem;
  text-align: left;
  text-shadow: 0.02rem 0.02rem 0.03rem rgba(0, 0, 0, 0.1);
}
.root-body-content.child-page .child-page-title::before {
  height: 0.15rem;
  margin-right: 0.06rem;
  display: inline-block;
  width: 0.04rem;
  border-radius: 0.06rem;
  background-color: #5f5f5f;
  position: relative;
  top: 0.02rem;
  content: ' ';
}
.root-body-sider {
  width: 2.85rem;
  position: relative;
  z-index: 1;
}
.root-footer {
  position: relative;
  z-index: 2;
  padding: 0.7rem 0 0 0;
  box-sizing: border-box;
  background-color: #3F4E4F;
}
.root .mobile-tips {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ccc;
}
.root .mobile-tips img {
  width: 0.45rem;
}
.root .mobile-tips span {
  margin: 0 0.05rem;
  color: #fff;
  font-weight: 700;
}
.root-header {
  height: 0.7rem;
  background-color: #454545;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
}
.root-header a:hover {
  text-decoration: none !important;
}
.root-header-menu {
  width: 100%;
  animation: fade-in-key 0.5s linear 1;
  position: absolute;
  top: 0.7rem;
  background-color: #2C3639;
}
.root-header-menu a {
  color: #fff;
}
.root-header-menu-item {
  width: 100%;
  padding: 0.12rem 0.2rem;
  border-top: 0.01rem solid rgba(255, 255, 255, 0.1);
  font-size: 0.16rem;
  box-sizing: border-box;
}
.root-header-container {
  width: 10rem;
  position: relative;
  padding: 0 0.12rem;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.root-header-container-guide,
.root-header-container-tools {
  width: 100%;
  font-size: 0.2rem;
  display: flex;
  align-items: center;
}
.root-header-container-guide a,
.root-header-container-tools a {
  color: #fff;
  border-bottom: 0.01rem solid transparent;
  transition: all 0.5s linear;
}
.root-header-container-guide a:hover,
.root-header-container-tools a:hover {
  text-shadow: 0 0 0.15rem rgba(255, 255, 255, 0.6);
  border-bottom: 0.01rem solid #fff;
}
.root-header-container-guide a.search,
.root-header-container-tools a.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 0.12rem;
  padding: 0.02rem 0.12rem;
  width: 1rem;
  color: #888;
  background-color: #fff;
  border-radius: 0.4rem;
}
.root-header-container-guide span,
.root-header-container-tools span {
  color: #888;
  margin: 0 0.12rem;
}
.root-header-container-tools {
  justify-content: flex-end;
}
.root-header-container-tools .anticon {
  color: #fff;
  font-size: 0.22rem;
  cursor: pointer;
}
.root-header-container-guide {
  justify-content: flex-start;
}
.root-header-container-guide.active {
  font-weight: 700;
}
.root-header-container-guide .ant-badge {
  font-size: 0.2rem;
  margin: 0;
  color: #fff;
}
.root-header-container-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100%;
  position: relative;
  font-size: 0.18rem;
  font-weight: 300;
  text-shadow: 0.02rem 0.02rem 0.05rem rgba(0, 0, 0, 0.2);
}
.root-header-container-logo a {
  position: relative;
}
.root-header-container-logo a:hover img {
  transform-origin: top center;
  transform: scale(0.65);
}
.root-header-container-logo a:hover .root-header-container-logo-tips {
  opacity: 1;
  transform: translateY(0);
}
.root-header-container-logo-tips {
  position: absolute;
  bottom: -0.08rem;
  font-size: 0.11rem;
  width: 100%;
  text-align: center;
  opacity: 0;
  color: #eee;
  transition: all 0.3s ease;
  transform: translateY(0.2rem);
}
.root-header-container-logo img {
  width: 0.9rem;
  transition: transform 0.3s ease;
}
.root-header-container .ant-input-search {
  width: 1.5rem;
  margin: 0;
}
.root-header-container .ant-input-search .ant-input {
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.3s linear;
  border-color: #1f1f1f;
  font-size: 0.12rem;
  color: #ddd;
}
.root-header-container .ant-input-search .ant-input::-webkit-input-placeholder {
  color: #999;
}
.root-header-container .ant-input-search .ant-input:focus {
  background-color: #fff;
  color: #333;
}
.root-header-container .ant-input-search .ant-input-suffix {
  right: 0;
}
.root-header-container .ant-input-search .ant-input-suffix .ant-input-search-icon {
  color: #1f1f1f;
}
.root .fixed-layer {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 100%;
  width: 100%;
}
.root .fixed-layer .anticon {
  color: #fff;
  font-size: 0.2rem;
}
.ant-btn-primary {
  background-color: #454545;
  border-color: #454545;
}
.ant-btn:hover,
.ant-btn:focus {
  border-color: #454545;
  color: #FFF;
  background-color: #5f5f5f;
}
@media screen and (max-width: 1000px) {
  .article {
    margin-top: 0.12rem;
  }
  .roll-selection {
    width: 100%;
  }
  .root-body {
    width: 100%;
    min-width: 100%;
    padding: 0.12rem 0;
    margin-top: 0;
  }
  .root-body-content {
    width: 100%;
  }
  .root-body-content.child-page {
    width: 100%;
  }
  .root-body-sider {
    display: none;
  }
  .root-body .article-list-item {
    margin: 0;
    width: 100%;
  }
  .root-body .quan-list-content-card {
    width: 100%;
  }
  .footer-content {
    width: 60%;
    left: 20%;
    margin-bottom: 0.44rem;
  }
  .mobile-tips {
    display: flex !important;
    z-index: 999;
  }
  .footer-content-links {
    display: none;
  }
  .extension,
  .article,
  .lunar {
    display: none;
  }
  .extension.meme-extension,
  .article.meme-extension,
  .lunar.meme-extension {
    display: block;
    margin-top: 0;
  }
  .gpt3-condition-item {
    margin-right: 0.3rem;
    margin-bottom: 0.2rem;
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-input-placeholder { /* WebKit browsers */
　color: #fff !important
}

body,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
img,
legend,
li,
ol,
p,
ul {
    margin: 0;
    padding: 0;
}

fieldset,
img {
    border: none;
}

address,
caption,
cite,
code,
dfn,
th,
var {
    font-style: normal;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

input, textarea {
    outline: none !important;
    border: none;
}
a {
  text-decoration: none;
}
body,
html {
  width: 100%;
  height: 100%;
  /* font-size: .28rem; */
}

html {
  overflow-x: hidden;
}

body {

  text-rendering: opximizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: PingFangSC-Regular, verdana, Arial !important;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, Arial, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "PingFang", "Helvetica Neue", STHeiti, "Microsoft Yahei", Tahoma, SimSun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  background-color: #f5f5f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.flex {
  display: flex;
}
.flex-direction-row {
  flex-direction: row
}
.flex-direction-column {
  flex-direction: column;
}

.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-around {
  justify-content: space-around;
}

.flex-align-start {
  align-items: flex-start;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-align-center {
  align-items: center;
}
.flex-align-baseline {
  align-items: baseline;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.wp100 {
  width: 100%;
}
.hp100 {
  height: 100%;
}

input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #e38786;
  /* placeholder字体大小  */
  font-size: .12rem;
}

.am-toast-notice {
  transform: scale(1.2)
}
.ant-list-item {
  /* padding: 20px 0; */
  padding: 0;
  padding-bottom: 0.15rem;
  margin-bottom: 0.15rem;
  border-bottom-color: #f5f5f5 !important;

}
.ant-list-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.ant-btn-circle.ant-btn-sm {
  font-size: 0.1rem;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-item:last-child {
  border-bottom: 0;
}
.subsribe-bell {
  font-size: 0.14rem;
  color: #07c160;
  cursor: pointer;
  margin-left: 0.06rem;
  display: inline-block;
  position: relative;
  top: -0.01rem;
  animation-name: shake-key;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes shake-key {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(0);
  }
  60% {
    transform: rotate(15deg);
  }
  70% {
    transform: rotate(-15deg);
  }
  80% {
    transform: rotate(15deg);
  }
  90% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes fade-in-key {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
}

a:focus {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: #e5e5e6;
          text-decoration-color: #e5e5e6;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  text-underline-offset: 0.05rem
}
.link-container-link {
  color: #888;
}
.link-container-link:hover {
  color: #000;
}
.ant-btn-circle {
  border-color: transparent;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0,0,0,.05);
  color: #bbb;
  transition: all .3s ease-in;
  /* width: 25px;
  height: 25px; */
  /* font-weight: 300; */
}
/* .anticon-picture { */
  /* font-size: 12px !important; */
  /* line-height: 12px; */
/* } */
/* .ant-btn-group { */
  /* height: 25px;
  line-height: 25px; */
/* } */
.ant-btn-ghost {
  color: #bbb;
  border-color: transparent;
  box-shadow: 0 0 0.05rem 0.03rem rgba(0,0,0,.05);
}
.pic-modal .ant-modal-confirm-content {
  margin-left: 0 !important;
}
.pic-modal .anticon, .pic-modal .ant-modal-confirm-title {
  display: none;
}
/* .pic-modal .ant-modal-confirm-btns, .pic-modal .ant-modal-confirm-btns .ant-btn {
  width: 100%;
} */
.ant-switch-checked {
  background-color: #15878b;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: none!important;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}
.ant-back-top {
  bottom: 1.5rem;
}

.ant-modal-confirm-content {
  margin-left: 0 !important;
}
@media screen and (max-width: 1000px) {
  .ant-modal {
    width: 90% !important;
  }
}

.ant-modal {
  top: .5rem;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 0;
}
.ant-modal-confirm .ant-modal-body {
  padding: 0.06rem 0.12rem;
}
.pop-closable {
  position: absolute;
  bottom: -0.45rem;
  width: calc(100% - 0.24rem);
  display: flex;
  justify-content: center;
  padding: 0.1rem 0;
  color: rgba(255,255,255,.5);
  cursor: pointer;
  transition: color .3s ease;
  font-size: 0.24rem;
  &:hover {
    color: rgba(255,255,255,1);
  }
}
